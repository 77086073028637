import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Typography
} from '@mui/material';
import { FC } from 'react';

interface ICollapseSetting {
  expanded: boolean;
  onChange: () => void;
  title: string;
}

export const CollapseSettingItem: FC<ICollapseSetting> = ({
  expanded,
  onChange,
  title,
  children,
}) => {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
