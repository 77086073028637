import { MenuItem } from '@mui/material';

export const renderMenuItem = (
  key: string,
  value: string,
  emptyPlaceholder?: string
) => (
  <MenuItem
    key={key}
    value={key ?? ''}
    sx={!value ? { color: '#555' } : undefined}
  >
    {value ? value : emptyPlaceholder}
  </MenuItem>
);
