import {
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField as TextFieldMUI,
  Box,
  TextFieldProps,
  SxProps,
} from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dateVars } from 'common/constants';
import moment, { Moment } from 'moment';
import { FC } from 'react';

interface IFormControl {
  disabled?: boolean;
  readonly?: boolean;
}

export interface ITextField {
  id: string;
  name: string;
  title: string;
  value?: string | number;
  handleChange: (event: any) => void;
  readOnly?: boolean;
  type?: string;
  error?: string;
}

export interface IFormTextField {
  id: string;
  name: string;
  title: string;
  value?: string | number;
  handleChange: (event: any) => void;
  readOnly?: boolean;
  type?: string;
  error?: string;
  textFieldProps?: TextFieldProps;
}

export interface IDatePicker {
  date: Date;
  handleChange: (newValue: Date | undefined) => void;
  error?: string;
}

export interface IMonthPicker {
  value: Date;
  onChange: (value: Moment) => void;
  inputSx?: SxProps;
  fullWidth?: boolean;
}

export interface ISelector extends IFormControl {
  options?: string[];
  label: string;
  id: string;
  name?: string;
  value: string;
  handleChange: (e: any) => void;
  handleOpen?: () => void;
  nullable?: boolean;
}

export interface ILabeledCheckbox {
  label: string;
  id: string;
  name?: string;
  isChecked: boolean;
  handleChange: (e: any) => void;
}

export function TextField(props: ITextField) {
  const { title, id, value, name, handleChange, readOnly, type, error } = props;
  return (
    <FormControl fullWidth={true}>
      <TextFieldMUI
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        sx={{ px: '0' }}
        variant="standard"
        label={title}
        type={type}
        error={!!error}
        helperText={error}
      />
    </FormControl>
  );
}

export const FormTextField = ({
  type,
  title,
  id,
  name,
  value,
  handleChange,
  error,
  textFieldProps,
}: IFormTextField) => {
  return (
    <TextFieldMUI
      margin="normal"
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      variant="standard"
      label={title}
      error={!!error}
      helperText={error}
      fullWidth
      {...textFieldProps}
    />
  );
};

export const MonthPicker: FC<IMonthPicker> = ({
  value,
  onChange,
  fullWidth,
}) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <MUIDatePicker
      inputFormat="MMM yy"
      views={['month', 'year']}
      minDate={moment(dateVars.min)}
      maxDate={moment(dateVars.max)}
      openTo="month"
      renderInput={(params) => (
        <TextFieldMUI
          {...params}
          helperText={null}
          variant="standard"
          fullWidth={fullWidth}
        />
      )}
      value={moment(value)}
      onChange={onChange}
    />
  </LocalizationProvider>
);

export function DatePicker(props: IDatePicker) {
  return (
    <FormControl fullWidth={true}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label="Date"
          inputFormat="DD.MM.YYYY"
          value={props.date}
          onChange={props.handleChange}
          renderInput={(params) => (
            <TextFieldMUI
              {...params}
              sx={{ px: '0' }}
              variant="standard"
              error={!!props.error}
              helperText={props.error}
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

export const Selector: FC<ISelector> = (props) => {
  const handleChange = (e: SelectChangeEvent) => props.handleChange(e);
  return (
    <FormControl fullWidth={true} variant="standard">
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        value={props.value}
        id={props.id}
        label={props.label}
        name={props.name}
        onChange={handleChange}
        onOpen={props.handleOpen}
        disabled={props.disabled}
        readOnly={props.readonly}
      >
        {props.nullable && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {props.options
          ? props.options.map((option, key) => (
              <MenuItem value={option} key={key}>
                {option}
              </MenuItem>
            ))
          : props.children}
      </Select>
    </FormControl>
  );
}

export function LabeledCheckbox(props: ILabeledCheckbox) {
  const { label, id, name, isChecked, handleChange } = props;
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleChange}
            name={name}
            id={id}
          />
        }
        label={label}
      />
    </Box>
  );
}
