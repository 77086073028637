import { useState } from 'react';
import { IEntityContext } from './useEntityContext';

export interface IContextMenu<T> {
  anchorEl?: HTMLElement | null;
  menuOpen: boolean;
  hideMenu: () => void;
  closeMenu: () => void;
  menuClick: (event: React.MouseEvent<HTMLButtonElement>, target: T) => void;
}

export function useContextMenu<T>(context: IEntityContext<T>): IContextMenu<T> {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  function hideMenu() {
    setAnchorEl(null);
  }

  function closeMenu() {
    setAnchorEl(null);
    context.reset();
  }

  function menuClick(event: React.MouseEvent<HTMLButtonElement>, target: T) {
    setAnchorEl(event.currentTarget);
    context.set(target);
  }

  return { anchorEl, menuOpen, hideMenu, closeMenu, menuClick };
}
