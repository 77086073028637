import { ArrowDropDown } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { ICategory } from 'common/entities';
import { useAppSelector } from 'core/hooks/hooks';
import { categoriesSelector } from 'core/store/selectors/categories.selector';
import { FC, useEffect, useMemo, useState } from 'react';

const defaultId = 'categorySelector';

interface IOption {
  title: string;
  value: string;
  displayName: string;
  targetCategory: ICategory;
  dropdown?: IOption[];
  hasChildren?: boolean;
  parent?: string;
}

const createOption = (
  category: ICategory,
  parentCategory?: ICategory,
  hasChildren?: boolean
): IOption => ({
  value: category.id,
  title: category.name,
  displayName: parentCategory
    ? `${parentCategory.name}: ${category.name}`
    : category.name,
  parent: parentCategory?.id,
  targetCategory: category,
  hasChildren
});

export interface ICategorySelector {
  value: string;
  onCategoryChange: (category: ICategory) => void;
}

export const CategorySelector: FC<ICategorySelector> = ({
  value,
  onCategoryChange
}) => {
  const { categories } = useAppSelector(categoriesSelector);
  const [selected, setSelected] = useState<IOption | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expand, setExpand] = useState<string | undefined>();

  const listOptions = useMemo(
    () =>
      categories
        .filter(c => !c.parentCategoryId)
        .flatMap(parentCat => {
          const children = categories.filter(
            c => c.parentCategoryId === parentCat.id
          );
          return [
            createOption(parentCat, undefined, !!children?.length),
            ...children.map(c => createOption(c, parentCat))
          ];
        }),
    [categories]
  );

  useEffect(() => {
    if (value && value !== selected?.value) {
      const targetOption = listOptions?.find(o => o.value === value);
      targetOption && setSelected(targetOption);
    }
  }, [value]);

  useEffect(() => {
    selected &&
      selected.value !== value &&
      onCategoryChange(selected.targetCategory);
  }, [selected]);

  const handleClickItem = (option: IOption) => {
    setSelected(option);
    option.hasChildren && setExpand(option.value);
    (option.parent ||
      !option.hasChildren ||
      (option.hasChildren && expand === option.value)) &&
      setIsOpen(false);
  };

  const handleToggleExpand = (event: any, option: IOption) => {
    event.stopPropagation();
    option.value === expand ? setExpand(undefined) : setExpand(option.value);
  };

  const renderOption = (option: IOption) => (
    <MenuItem
      key={option.value}
      value={option.value}
      onClick={() => handleClickItem(option)}
      sx={{
        pl: option.parent ? 4 : undefined,
        display: option.parent && option.parent !== expand ? 'none' : undefined
      }}
    >
      <ListItemText>{option.title}</ListItemText>
      {option.hasChildren && (
        <Typography variant="body2" color="text.secondary">
          <IconButton
            size="small"
            sx={{ p: 0 }}
            onClick={event => handleToggleExpand(event, option)}
          >
            <ArrowDropDown
              sx={{
                transform:
                  option.value === expand ? 'rotate(180deg)' : undefined
              }}
            />
          </IconButton>
        </Typography>
      )}
    </MenuItem>
  );

  const categoryOptions = useMemo(
    () => listOptions.map(renderOption),
    [listOptions, expand]
  );

  const handleClose = event => {
    isOpen &&
      event.target?.className?.includes &&
      (event.target.className.includes('MuiModal-backdrop') ||
        event.target.className.includes('MuiBackdrop-root')) &&
      setIsOpen(false);
  };

  return (
    <FormControl fullWidth={true} variant="standard">
      <InputLabel htmlFor={defaultId}>Category</InputLabel>
      <Select
        open={isOpen}
        value={selected?.value ?? ''}
        id={defaultId}
        name={defaultId}
        onClose={handleClose}
        onOpen={() => setIsOpen(true)}
        MenuProps={{
          id: `${defaultId}-menu`,
          sx: {
            maxHeight: '300px'
          }
        }}
        renderValue={_ => <>{selected?.displayName ?? ''}</>}
      >
        <MenuItem value=""></MenuItem>
        {categoryOptions}
      </Select>
    </FormControl>
  );
};
