import { useEffect, useState } from 'react';
import { IFormState, IValidationState } from '../../common/types';
import { useAppDispatch } from './hooks';
import { useForm } from './useForm';

export function useEntityEditForm<T>(
  target: string | undefined,
  initialState: IFormState,
  lookupTarget: T | undefined,
  isOpen: boolean,
  createFormState: (entity: T) => IFormState,
  addEntityAction: () => any,
  updateEntityAction: () => any,
  getLookup?: (target: string) => any,
  validationSchema?: IValidationState
) {
  const {
    formState,
    setFormState,
    updateFormState,
    updateFormStateMultiple,
    handleSubmit,
  } = useForm(initialState, validationSchema ?? {}, onSubmit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen && target) {
      getLookup && dispatch(getLookup(target));
    } else {
      setFormState(initialState);
    }
  }, [isOpen]);

  useEffect(() => {
    if (target && lookupTarget) {
      const newFormState = createFormState(lookupTarget);
      setFormState(newFormState);
    }
  }, [lookupTarget, target]);

  function onSubmit(event) {
    if (target) {
      dispatch(updateEntityAction());
    } else {
      dispatch(addEntityAction());
    }
  }

  return {
    formState,
    updateEntityProperty: updateFormState,
    updateEntityProperties: updateFormStateMultiple,
    handleSubmit,
  };
}
