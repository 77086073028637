import { IMonth } from "common/types";

const dateOptions: any = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
};

const monthOptions: any = {
  month: 'long',
  year: 'numeric'
}

export function getDayTitle(date: Date) {
  return date.toLocaleDateString('en-gb', dateOptions);
}

export function getMonthTitle(date: Date): string {
  return date.toLocaleDateString('en-gb', monthOptions);
}

export function getMonthItem(month: Date): IMonth {
  return {
    month: month.getMonth(),
    year: month.getFullYear()
  }
}

export function isToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function isCurrentMonth(date: Date) {
  const today = new Date();
  return (
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function compareMonth(dateA: Date, dateB: Date) {
  return (
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  );
}

export function compareDate(dateA: Date, dateB: Date) {
  return (
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  );
}
