import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Typography
} from '@mui/material';
import { CategorySelector } from 'components/controls/CategorySelector';
import { useNotificationContext } from 'core/providers/NotificationContextProvider';
import { getChildCategories } from 'core/services/category.service';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ICategory } from '../../../common/entities';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/hooks';
import { useContextMenu } from '../../../core/hooks/useContextMenu';
import { useEntityContext } from '../../../core/hooks/useEntityContext';
import {
  deleteCategory,
  listCategories
} from '../../../core/store/actions/categories.actions';
import { categoriesSelector } from '../../../core/store/selectors/categories.selector';
import NotificationPanel from '../../Shared/NotificationPanel';
import EditCategoryForm from './EditCategoryForm';

type CategoryWithChildren = ICategory & { children: ICategory[] };

const Categories: FC = () => {
  const dispatch = useAppDispatch();
  const { categories, isLoading, error, lastAction } =
    useAppSelector(categoriesSelector);
  const [dataFetched, setDataFetched] = useState(false);
  const [isEditCategoryPanelOpen, setIsEditCategoryPanelOpen] = useState(false);
  const [listError, setListError] = useState<string>();
  const { showNotification } = useNotificationContext();
  const context = useEntityContext<string>();
  const contextMenu = useContextMenu<string>(context);

  const groupedCategories: CategoryWithChildren[] = useMemo(
    () =>
      categories.reduce(
        (prev, current) =>
          !current.parentCategoryId
            ? [
                ...prev,
                {
                  ...current,
                  children: getChildCategories(current.id, categories)
                }
              ]
            : prev,
        []
      ),
    [categories]
  );

  useEffect(() => {
    dispatch(listCategories());
    setDataFetched(true);
  }, []);

  useEffect(() => {
    switch (lastAction) {
      case listCategories.rejected.type:
        setListError(error);
        break;
      case deleteCategory.fulfilled.type:
        showNotification({
          message: `Category has been deleted`
        });
        break;
      case deleteCategory.rejected.type:
        showNotification({
          isError: true,
          message: `Error: ${error}`
        });
        break;
    }
  }, [lastAction]);

  const handleCloseEditPanel = () => {
    setIsEditCategoryPanelOpen(false);
    context.reset();
  };

  const createListItemMenu = (targetCategory: ICategory) => (
    <IconButton
      edge="end"
      onClick={event => contextMenu.menuClick(event, targetCategory.id)}
    >
      <MoreHorizIcon></MoreHorizIcon>
    </IconButton>
  );

  const categoriesList = useMemo(
    () =>
      groupedCategories.map(grCat => (
        <>
          <ListItem
            key={grCat.id}
            secondaryAction={createListItemMenu(grCat)}
            sx={{ borderBottom: '1px solid #eee' }}
          >
            <ListItemText primary={grCat.name} />
          </ListItem>
          {!!grCat.children.length &&
            grCat.children.map(cCat => (
              <ListItem
                key={cCat.id}
                secondaryAction={createListItemMenu(cCat)}
                sx={{ borderBottom: '1px solid #eee', pl: 2 }}
              >
                <ListItemText primary={cCat.name} />
              </ListItem>
            ))}
        </>
      )),
    [groupedCategories]
  );

  const renderItemMenu = useCallback(() => {
    const handleEditCategory = () => {
      contextMenu.hideMenu();
      setIsEditCategoryPanelOpen(true);
    };

    const handleDeleteCategory = () => {
      context.current && dispatch(deleteCategory(context.current));
      contextMenu.closeMenu();
    };

    return (
      <Menu
        open={contextMenu.menuOpen}
        anchorEl={contextMenu.anchorEl}
        onClose={contextMenu.closeMenu}
      >
        <MenuItem onClick={handleEditCategory}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteCategory}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    );
  }, [contextMenu, context]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 1,
          pb: 1.5
        }}
      >
        <Typography variant="h2">Categories</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setIsEditCategoryPanelOpen(true)}
        >
          Add category
        </Button>
      </Box>
      <Paper sx={{ p: 2 }}>
        {!listError &&
          (dataFetched && !isLoading ? (
            categories.length ? (
              <>
                <List dense={true}>{categoriesList}</List>
                {renderItemMenu()}
              </>
            ) : (
              <Alert severity="warning">No categories</Alert>
            )
          ) : (
            <Skeleton variant="rectangular" height="60vh" />
          ))}
        {listError && <Alert severity="error">{listError}</Alert>}
        <EditCategoryForm
          isOpen={isEditCategoryPanelOpen}
          closePanel={handleCloseEditPanel}
          categoryId={context.current}
          title={context.current ? 'Edit category' : 'Add category'}
        />
        <NotificationPanel />
      </Paper>
    </Box>
  );
};

export default Categories;
