import {
  Box,
  Grid,
  SxProps,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import MonthExpenses from 'components/Expenses/MonthExpenses';
import MobileHeader from 'components/mobile/MobileHeader';
import { MonthSelector } from 'components/Shared/MonthSelector';
import { useAppSelector } from 'core/hooks/hooks';
import { periodSelector } from 'core/store/selectors/period.selector';
import { FC, useMemo, useState } from 'react';
import ExpensesPage from './components/Expenses/Expenses';

const mobileToggleButtonsSx: {
  container: SxProps;
  button: SxProps;
} = {
  container: {
    display: 'flex'
  },
  button: {
    flexGrow: 1
  }
};

const mobileTabs = {
  expenses: 'expenses',
  statistic: 'statistic'
};

const Main: FC = () => {
  const [currentMobileTab, setCurrentMobileTab] = useState<string>(
    mobileTabs.expenses
  );

  const currentMonth = useAppSelector(periodSelector('expenses'));

  const handleChangeMobileTab = (_: any, tab: string) =>
    setCurrentMobileTab(tab);

  const mobileToggleTabButtons = useMemo(
    () => (
      <ToggleButtonGroup
        size="small"
        exclusive
        sx={mobileToggleButtonsSx.container}
        value={currentMobileTab}
        onChange={handleChangeMobileTab}
      >
        <ToggleButton
          value={mobileTabs.expenses}
          key={mobileTabs.expenses}
          sx={mobileToggleButtonsSx.button}
        >
          Expenses
        </ToggleButton>
        <ToggleButton
          value={mobileTabs.statistic}
          key={mobileTabs.statistic}
          sx={mobileToggleButtonsSx.button}
        >
          Statistic
        </ToggleButton>
      </ToggleButtonGroup>
    ),
    [currentMobileTab, handleChangeMobileTab]
  );

  return (
    <>
      <MobileHeader>
        <Box sx={{ mb: '10px' }}>
          <MonthSelector target="expenses" fullWidth />
        </Box>
        <Box>{mobileToggleTabButtons}</Box>
      </MobileHeader>
      <Box
        sx={{
          pt: {
            xs: '140px',
            md: '5px'
          },
          pb: {
            xs: '70px',
            md: 0
          }
        }}
      >
        <Box sx={{ mb: '20px', display: { xs: 'none', md: 'block' } }}>
          <MonthSelector target="expenses" />
        </Box>
        <Grid
          container
          spacing={3}
          sx={{
            pt: 0
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pt: 0,
              display: {
                xs: currentMobileTab === mobileTabs.expenses ? 'block' : 'none',
                md: 'block'
              }
            }}
          >
            <ExpensesPage month={currentMonth} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pt: 0,
              display: {
                xs:
                  currentMobileTab === mobileTabs.statistic ? 'block' : 'none',
                md: 'block'
              }
            }}
          >
            <MonthExpenses month={currentMonth} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Main;
