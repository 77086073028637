const currentDate = new Date();

export const dateVars = {
  current: currentDate,
  max: new Date(currentDate.getFullYear() + 10, 0, 1),
  min: new Date(2000, 0, 1)
}

export const emptyCells = {
  budget: '<no budget>',
  category: '<no category>',
  description: '<no description>'
}

export const routes = {
  login: '/login',
  registration: '/registration',
  settings: '/settings',
  budgets: '/budgets',
  budget: '/budget/:budgetId',
  category: '/category/:categoryId',
  confirmEmail: '/confirmEmail',
  confirmEmailCallback: '/confirmEmailCallback',
  home: '/'
}

export const settingPages = {
  profile: 'profile',
  categories: 'categories',
  preferences: 'preferences'
};

export const defaultCurrency = '₴';
