import { Box, Button } from '@mui/material';
import { ICategory, IExpense, IExpenseBase } from 'common/entities';
import { IFormState, IValidationState } from 'common/types';
import { CategorySelector } from 'components/controls/CategorySelector';
import { renderMenuItem } from 'components/utils/selectOptions';
import { useAppSelector } from 'core/hooks/hooks';
import { useEntityEditForm } from 'core/hooks/useEntityEditForm';
import { useNotificationContext } from 'core/providers/NotificationContextProvider';
import {
  addExpense,
  getExpense,
  updateExpense
} from 'core/store/actions/expenses.actions';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { expensesSelector } from 'core/store/selectors/expenses.selector';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo } from 'react';
import RightDrawer from '../../BaseLayout/RightDrawer';
import { DatePicker, Selector, TextField } from '../../controls/formControls';

export interface IEditExpenseForm {
  isOpen: boolean;
  closePanel: () => void;
  targetDate?: Date;
  expenseId?: string;
}

const EditExpenseForm: FC<IEditExpenseForm> = ({
  expenseId,
  isOpen,
  closePanel,
  targetDate
}) => {
  const initialState: IFormState = {
    date: { value: targetDate },
    amount: { value: 0 },
    category: { value: '' },
    budget: { value: '' },
    description: { value: '' }
  };
  const { showNotification } = useNotificationContext();
  const { budgets } = useAppSelector(budgetsSelector);
  const { currentExpense, lastAction, error } =
    useAppSelector(expensesSelector('expenses'));

  const validationSchema: IValidationState = {
    amount: {
      required: true,
      customValidator: (formState: IFormState) => {
        const numValue = Number(formState.amount?.value);
        return !isNaN(numValue) && numValue > 0;
      },
      customValidatorMessage: 'Value should be a positive number'
    },
    date: {
      required: true,
      customValidator: (formState: IFormState) => {
        const dateVal = moment(formState.date?.value);
        return dateVal.isValid();
      },
      customValidatorMessage: 'Invalid date'
    }
  };

  const { formState, updateEntityProperty, handleSubmit } =
    useEntityEditForm<IExpense>(
      expenseId,
      initialState,
      currentExpense,
      isOpen,
      createFormState,
      addExpenseAction,
      updateExpenseAction,
      getExpense,
      validationSchema
    );

  useEffect(() => {
    targetDate && updateEntityProperty('date', targetDate);
  }, [targetDate]);

  useEffect(() => {
    switch (lastAction) {
      case addExpense.fulfilled.type:
        closePanel();
        showNotification({
          message: `New expense has been added`
        });
        break;
      case addExpense.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
      case getExpense.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        closePanel();
        break;
      case updateExpense.fulfilled.type:
        closePanel();
        showNotification({
          message: `Expense has been updated`
        });
        break;
      case updateExpense.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  const handleUpdateDate = (newValue: any) => {
    updateEntityProperty('date', newValue?.toDate());
  };

  const handleInputChange = event => {
    updateEntityProperty(event.target.name, event.target.value);
  };

  const handleCategoryChange = useCallback((category: ICategory) => {
    updateEntityProperty('category', category.id);
    category.defaultBudget &&
      updateEntityProperty('budget', category.defaultBudget);
  }, [updateEntityProperty])

  function createExpense(updateTime?: boolean): IExpenseBase {
    const numberValue = Number(formState.amount.value);
    const currDate = new Date();
    const expenseDate = new Date(formState.date.value);
    updateTime &&
      expenseDate.setHours(
        currDate.getHours(),
        currDate.getMinutes(),
        currDate.getSeconds()
      );
    return {
      date: expenseDate,
      amount: !Number.isNaN(numberValue) ? numberValue : 0,
      categoryId: formState.category.value
        ? formState.category.value
        : undefined,
      budgetId: formState.budget.value ? formState.budget.value : undefined,
      description: formState.description.value,
      agent: 'webapp'
    };
  }

  function createFormState(expense: IExpense): IFormState {
    return {
      date: { value: expense.date },
      category: { value: expense.categoryId ?? '' },
      budget: { value: expense.budgetId ?? '' },
      description: { value: expense.description },
      amount: { value: expense.amount?.toString() }
    };
  }

  function addExpenseAction() {
    return addExpense(createExpense(true));
  }

  function updateExpenseAction() {
    if (!expenseId) return;
    return updateExpense({
      id: expenseId,
      expense: createExpense()
    });
  }

  const budgetOptions = useMemo(
    () =>
      [renderMenuItem('', '', '<no budget>')].concat(
        budgets.map(b => renderMenuItem(b.id, b.name))
      ),
    [budgets]
  );

  return (
    <RightDrawer
      title={expenseId ? 'Update expense' : 'Add Expense'}
      isOpen={isOpen}
      closePanel={closePanel}
    >
      <Box component="form" onSubmit={handleSubmit}>
        <DatePicker
          date={formState.date.value}
          handleChange={handleUpdateDate}
          error={formState.date.error}
        />
        <CategorySelector
          value={formState.category.value}
          onCategoryChange={handleCategoryChange}
        />
        <Selector
          label="Budget"
          id="budget"
          name="budget"
          value={formState.budget.value}
          handleChange={handleInputChange}
        >
          {budgetOptions}
        </Selector>
        <TextField
          type="number"
          id="amount"
          name="amount"
          title="Amount"
          value={formState.amount.value}
          handleChange={handleInputChange}
          error={formState.amount.error}
        />
        <TextField
          type="text"
          id="description"
          name="description"
          title="Description"
          value={formState.description.value}
          handleChange={handleInputChange}
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'right', marginTop: '30px' }}
        >
          <Button type="submit">
            {expenseId ? 'Update expense' : 'Add expense'}
          </Button>
        </Box>
      </Box>
    </RightDrawer>
  );
};

export default EditExpenseForm;
