import { Box, Button, Paper, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import { logout } from 'core/services/dispatch.service';
import { authSelector } from 'core/store/selectors/auth.selector';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

const Profile: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleLogout = () => logout(history, dispatch);

  const { user } = useAppSelector(authSelector);

  return (
    <Box>
      <Typography variant="h2" sx={{ pt: 1, pb: 2 }}>
        Profile
      </Typography>
      <Paper sx={{ p: 2, minHeight: '60vh' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="subtitle1">
            Account:{' '}
            <Typography component="span" sx={{ fontWeight: 500 }}>
              {user?.email}
            </Typography>
          </Typography>
          <Button variant="outlined" size="small" onClick={handleLogout}>
            Log out
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Profile;
