import { getTokenInfo } from './auth.service';

export function isUserAuthenticated() {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      const dateNow = new Date();
      const decodedToken = getTokenInfo(token);
      return (decodedToken?.exp ?? 0) * 1000 > dateNow.getTime();
    } else {
      return false;
    }
  } catch (e) {
    console.log('invalid token', e);
    return false;
  }
}
