import { CssBaseline, ThemeProvider } from '@mui/material';
import { routes } from 'common/constants';
import ConfirmEmail from 'components/Auth/ConfirmEmail';
import MainLayout from 'components/BaseLayout/MainLayout';
import BudgetPage from 'components/Budgets/BudgetPage';
import CategoryPage from 'components/Categories/CategoryPage';
import Settings from 'components/Settings';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import { AppRoute, PrivateRoute } from './AppRoute';
import Main from './Main';
import { AuthLayout } from './components/Auth/AuthLayout';
import LoginPage from 'components/Auth/Login';
import { RegistrationPage } from './components/Auth/Registration';
import Budgets from './components/Budgets/Budgets';
import { theme } from './theme';
import { ConfirmEmailCallback } from 'components/Auth/ConfirmEmailCallback';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <AppRoute
            path={routes.login}
            component={LoginPage}
            layout={AuthLayout}
          />
          <AppRoute
            path={routes.registration}
            component={RegistrationPage}
            layout={AuthLayout}
          />
          <AppRoute
            path={routes.confirmEmail}
            component={ConfirmEmail}
            layout={AuthLayout}
          />
          <AppRoute
            path={routes.confirmEmailCallback}
            component={ConfirmEmailCallback}
            layout={AuthLayout}
          />
          <PrivateRoute
            path={routes.settings}
            component={Settings}
            layout={MainLayout}
          />
          <PrivateRoute
            path={routes.budgets}
            component={Budgets}
            layout={MainLayout}
          />
          <PrivateRoute
            path={routes.budget}
            component={BudgetPage}
            layout={MainLayout}
          />
          <PrivateRoute
            path={routes.category}
            component={CategoryPage}
            layout={MainLayout}
          />
          <PrivateRoute
            path={routes.home}
            component={Main}
            layout={MainLayout}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
