import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IUserCredentials } from '../../../common/entities';
import { endpoints } from '../../api/endpoints';
import { getTokenInfo } from '../../services/auth.service';
import { IToken } from 'common/types';

export type LoginPayloadAction = PayloadAction<
  IToken,
  string,
  { arg: IUserCredentials }
>;

export type RegisterPayloadAction = PayloadAction<
  string,
  string,
  { arg: IUserCredentials }
>;

export const login = createAsyncThunk(
  'auth/login',
  async (data: IUserCredentials, thunkAPI) => {
    try {
      const response = await axios.post(endpoints.auth.login, data);
      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        const tokenInfo = getTokenInfo(response.data.token);
        return tokenInfo ?? thunkAPI.rejectWithValue('No token');
      }
    } catch (e) {
      return thunkAPI.rejectWithValue('Unauthorized');
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (data: IUserCredentials, thunkAPI) => {
    try {
      const response = await axios
        .post(endpoints.auth.register, data)
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error.response.data;
        });
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const sendConfirmEmail = createAsyncThunk(
  'auth/sendConfirmEmail',
  async (data: { email: string }, thunkAPI) => {
    try {
      const response = await axios
        .post(endpoints.auth.sendConfirmEmail, data)
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error.response.data;
        });
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/confirmEmail',
  async (data: { token: string }, thunkAPI) => {
    try {
      const response = await axios
        .post(endpoints.auth.confirmEmail, data)
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error.response.data;
        });
      return response.data;
    } catch (e) {
      return e.message
        ? thunkAPI.rejectWithValue(e.message)
        : thunkAPI.rejectWithValue(e);
    }
  }
);
