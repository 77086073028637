import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../api/axiosClient';
import { IUserData } from "common/entities";
import { endpoints } from "core/api/endpoints";

export const getUserData = createAsyncThunk(
  'userData/get',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IUserData>(endpoints.userData);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
