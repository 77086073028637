import { Chip, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { emptyCells } from 'common/constants';
import { ICategory } from 'common/entities';
import { ITableColumn } from 'common/types';
import { MonthSelector } from 'components/Shared/MonthSelector';
import SortableTable from 'components/Shared/SortableTable';
import { useAppSelector } from 'core/hooks/hooks';
import { sumWithCurrency } from 'core/services/currency.service';
import { isExpenseInMonth } from 'core/services/expense.service';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { expensesSelector } from 'core/store/selectors/expenses.selector';
import { FC, useMemo } from 'react';

export interface ICategoryProps {
  category: ICategory;
  month: Date;
  background?: boolean;
}

interface ICategoryExpenseRow {
  id: string;
  budget: string;
  date: Date;
  description: string;
  amount: number;
}

const expensesColumns: ITableColumn<ICategoryExpenseRow>[] = [
  {
    id: 'budget',
    label: 'Budget'
  },
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'right'
  }
];

const customCellBuilder = (
  column: keyof ICategoryExpenseRow,
  row: ICategoryExpenseRow
): JSX.Element => {
  switch (column) {
    case 'date':
      return (
        <>
          {new Date(row.date).toLocaleDateString('uk-UA', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          })}
        </>
      );
    case 'description':
      return row.description ? (
        <>{row.description}</>
      ) : (
        <Typography component="span" sx={{ color: '#555' }}>
          {emptyCells.description}
        </Typography>
      );
    case 'amount':
      return <>{sumWithCurrency(row.amount)}</>;
    default:
      return <>{row[column]}</>;
  }
};

const Category: FC<ICategoryProps> = ({ month, category }: ICategoryProps) => {
  const { expenses } = useAppSelector(expensesSelector('category'));
  const categoryExpenses = useMemo(
    () =>
      expenses.filter(
        e => e.categoryId === category.id && isExpenseInMonth(e, month)
      ),
    [category, expenses, month]
  );
  const { budgets } = useAppSelector(budgetsSelector);

  const expensesRows = useMemo(
    () =>
      categoryExpenses
        .sort(
          (a, b) =>
            (new Date(a.date).getTime() - new Date(b.date).getTime()) * -1
        )
        .map(
          (e): ICategoryExpenseRow => ({
            id: e.id,
            budget:
              budgets.find(c => c.id === e.budgetId)?.name ??
              emptyCells.category,
            date: e.date,
            amount: e.amount,
            description: e.description ?? emptyCells.description
          })
        ),
    [budgets, categoryExpenses]
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ mr: 4 }}>
          <Chip
            label="Category"
            size="small"
            sx={{ mr: 1 }}
            variant="outlined"
            color="success"
          />{' '}
          {category.name}
        </Typography>
        <MonthSelector target="category" />
      </Box>
      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle1">Category expenses</Typography>
        <SortableTable
          columns={expensesColumns}
          rows={expensesRows}
          indexBy="id"
          customCellBuilder={customCellBuilder}
        />
      </Paper>
    </Box>
  );
};

export default Category;
