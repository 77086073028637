import { Box, Typography } from '@mui/material';
import { sumWithCurrency } from 'core/services/currency.service';
import { FC } from 'react';

export interface IBudgetMonthSummary {
  totalBudget: number;
  totalBudgetSpent: number;
  totalAccumulationSpent: number;
  currentBudgetBalance: number;
}

export const BudgetMonthSummary: FC<IBudgetMonthSummary> = ({
  totalBudget,
  totalBudgetSpent,
  totalAccumulationSpent,
  currentBudgetBalance
}) => {
  return (
    <Box sx={{ textAlign: 'right' }}>
      <Typography>
        Total month budget:{' '}
        <Typography component="span" sx={{ fontWeight: 500 }}>
          {sumWithCurrency(totalBudget)}
        </Typography>
      </Typography>
      <Typography>
        Total month budget spent:{' '}
        <Typography component="span" sx={{ fontWeight: 500 }}>
          {sumWithCurrency(totalBudgetSpent)}
        </Typography>
      </Typography>
      <Typography>
        Total month accumulation spent:{' '}
        <Typography component="span" sx={{ fontWeight: 500 }}>
          {sumWithCurrency(totalAccumulationSpent)}
        </Typography>
      </Typography>
      <Typography>
        Current budget balance:{' '}
        <Typography component="span" sx={{ fontWeight: 500 }}>
          {sumWithCurrency(currentBudgetBalance)}
        </Typography>
      </Typography>
    </Box>
  );
};
