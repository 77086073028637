import { useCallback, useState } from 'react';
import { IFormState, IValidationState } from '../../common/types';

export const useForm = (
  initialState: IFormState,
  validationSchema: IValidationState,
  callback: (event: any) => void
) => {
  const [formState, setFormState] = useState<IFormState>(initialState);
  const [hasErrorsState, setHasErrors] = useState<boolean>(false);
  let hasErrors = false;

  const setError = (propName: string, message: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [propName]: { value: prevState[propName].value, error: message },
    }));
  };

  const updateFormState = (propName: string, newValue: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [propName]: { value: newValue, error: '' },
    }));
  };

  const updateFormStateMultiple = (propValueEntries: [string, any][]) => {
    const newState: IFormState = {
      ...formState,
      ...Object.fromEntries(
        propValueEntries.map(([key, value]) => [key, { value, error: '' }])
      ),
    };
    setFormState(newState);
  };

  const validateState = useCallback(() => {
    Object.keys(validationSchema).forEach((name) => {
      const validation = validationSchema[name];
      if (
        validation.required &&
        (!formState[name].value ||
          (formState[name].value as any[]).length === 0)
      ) {
        setError(
          name,
          validation.customRequiredMessage ?? `${name} is required`
        );
        hasErrors = true;
      }
      if (
        validation.customValidator &&
        validation.customValidatorMessage &&
        !validation.customValidator(formState)
      ) {
        setError(name, validation.customValidatorMessage);
        hasErrors = true;
      }
    });
    setHasErrors(hasErrors)
    return hasErrors;
  }, [validationSchema, formState]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      validateState();
      if (!hasErrors) {
        callback(event);
      }
    },
    [hasErrors, hasErrorsState, formState, validateState]
  );

  return {
    formState,
    setFormState,
    updateFormState,
    updateFormStateMultiple,
    handleSubmit,
    hasErrors: hasErrorsState,
    setError
  };
};
