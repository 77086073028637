import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBudget, IUserData } from '../../../common/entities';
import {
  addBudget,
  deleteBudget,
  getBudget,
  listBudgets,
  setBudgetMonth,
  updateBudget
} from '../actions/budgets.actions';
import { getUserData } from '../actions/userData.actions';

interface IBudgetsState {
  budgets: IBudget[];
  isLoading: boolean;
  error?: string;
  lastAction: string;
  currentBudget?: IBudget;
  hasLoaded?: boolean;
}

const initialState: IBudgetsState = {
  budgets: [],
  isLoading: false,
  error: '',
  lastAction: '',
  currentBudget: undefined,
  hasLoaded: false
};

export const budgetsSlice = createSlice({
  name: 'budgets',
  initialState: { ...initialState },
  reducers: {
    resetCurrentBudget: (state: IBudgetsState) =>
      (state.currentBudget = undefined),
    resetBudgets(state, action: PayloadAction) {
      state.budgets = [];
      state.isLoading = false;
      state.currentBudget = undefined;
      state.error = '';
      state.hasLoaded = false;
      state.lastAction = '';
    }
  },
  extraReducers: {
    [getUserData.fulfilled.type]: (state, action: PayloadAction<IUserData>) => {
      state.isLoading = false;
      state.error = undefined;
      state.budgets = action.payload.budgets;
      state.hasLoaded = true;
    },
    [listBudgets.fulfilled.type]: (state, action: PayloadAction<IBudget[]>) => {
      state.isLoading = false;
      state.error = '';
      state.budgets = action.payload;
      state.lastAction = action.type;
      state.hasLoaded = true;
    },
    [listBudgets.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = state.lastAction = action.type;
    },
    [listBudgets.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [getBudget.fulfilled.type]: (state, action: PayloadAction<IBudget>) => {
      state.isLoading = false;
      state.error = '';
      state.currentBudget = action.payload;
      state.lastAction = action.type;
    },
    [getBudget.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = state.lastAction = action.type;
    },
    [getBudget.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [addBudget.fulfilled.type]: (state, action: PayloadAction<IBudget>) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [addBudget.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [addBudget.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [updateBudget.fulfilled.type]: (state, action: PayloadAction<IBudget>) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [updateBudget.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [updateBudget.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [deleteBudget.fulfilled.type]: (state, action: PayloadAction<IBudget>) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [deleteBudget.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [deleteBudget.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [setBudgetMonth.fulfilled.type]: (
      state,
      action: PayloadAction<IBudget>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [setBudgetMonth.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [setBudgetMonth.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    }
  }
});

export const { resetCurrentBudget, resetBudgets } = budgetsSlice.actions;

export default budgetsSlice.reducer;
