import { Box } from '@mui/material';
import BaseLayout from '../BaseLayout/BaseLayout';

export function AuthLayout(props: { children }) {
  const { children } = props;
  return (
    <BaseLayout showNavigation={false}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {children}
      </Box>
    </BaseLayout>
  );
}
