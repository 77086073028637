import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMonth } from 'common/types';
import { getMonthItem } from 'utils/dateUtils';

export interface IPeriodState {
  expenses: IMonth;
  budgetMonth: IMonth;
  category: IMonth;
  budget: IMonth;
}

interface SetPeriodActionPayload {
  target: keyof IPeriodState;
  month: IMonth;
}

const today = getMonthItem(new Date());
const initialState: IPeriodState = {
  expenses: today,
  budgetMonth: today,
  category: today,
  budget: today
};

const periodSlice = createSlice({
  name: 'period',
  initialState,
  reducers: {
    setPeriod(state, action: PayloadAction<SetPeriodActionPayload>) {
      state[action.payload.target] = action.payload.month;
    },
    resetPeriods(state, action: PayloadAction) {
      state = initialState;
    }
  }
});

export const { setPeriod, resetPeriods } = periodSlice.actions;
export default periodSlice.reducer;
