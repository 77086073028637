import { Alert, Box, Button, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IUserCredentials } from '../../common/entities';
import { IFormState, IValidationState } from '../../common/types';
import { useAppDispatch, useAppSelector } from '../../core/hooks/hooks';
import { useForm } from '../../core/hooks/useForm';
import { register } from '../../core/store/actions/auth.actions';
import {
  resetErrors,
  resetRegistration
} from '../../core/store/reducers/user.reducer';
import { authSelector } from '../../core/store/selectors/auth.selector';
import { FormTextField } from '../controls/formControls';
import { routes } from 'common/constants';

function createUserCredentials(formState: IFormState): IUserCredentials {
  return {
    email: formState.email.value,
    password: formState.password.value
  };
}

const confirmPasswordValidator = (formState: IFormState) =>
  !formState.passwordConfirm?.value ||
  formState.password?.value === formState.passwordConfirm?.value;

const initialFormState: IFormState = {
  email: { value: '' },
  password: { value: '' },
  passwordConfirm: { value: '' }
};

const validationState: IValidationState = {
  email: { required: true, customRequiredMessage: 'Enter email' },
  password: { required: true, customRequiredMessage: 'Enter password' },
  passwordConfirm: {
    required: true,
    customRequiredMessage: 'Enter password confirmation',
    customValidator: confirmPasswordValidator,
    customValidatorMessage: "Passwords don't match"
  }
};

export const RegistrationPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { error, errorStatus, registeredUserId } = useAppSelector(authSelector);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const unknownError = error && errorStatus !== 'E_USER_01';

  const registerCallback = event => {
    event.preventDefault();
    setIsSubmitted(true);
    dispatch(register(createUserCredentials(formState)));
  };

  const {
    formState,
    updateFormState,
    handleSubmit: submit,
    setError
  } = useForm(initialFormState, validationState, registerCallback);

  const handleInputChange = event =>
    updateFormState(event.target.name, event.target.value);

  const handleSubmit = event => {
    dispatch(resetErrors());
    submit(event);
  };

  useEffect(() => {
    return () => {
      if (registeredUserId) {
        dispatch(resetRegistration());
      }
    };
  }, []);

  useEffect(() => {
    if (isSubmitted && registeredUserId) {
      history.push(routes.confirmEmail);
    }
  }, [registeredUserId]);

  useEffect(() => {
    if (isSubmitted && error && errorStatus === 'E_USER_01') {
      setError('email', error);
    }
  }, [errorStatus]);

  return (
    <>
      <Typography component="h1" variant="h5">
        Registration
      </Typography>
      {isSubmitted && unknownError && <Alert severity="error">{error}</Alert>}
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <FormTextField
          type="text"
          id="email"
          name="email"
          title="email"
          handleChange={handleInputChange}
          error={formState.email.error}
          textFieldProps={{
            autoFocus: true,
            required: true
          }}
        />
        <FormTextField
          type="password"
          id="password"
          name="password"
          title="Password"
          handleChange={handleInputChange}
          error={formState.password.error}
          textFieldProps={{ required: true }}
        />
        <FormTextField
          type="password"
          id="passwordConfirm"
          name="passwordConfirm"
          title="Password confirm"
          handleChange={handleInputChange}
          error={formState.passwordConfirm.error}
          textFieldProps={{ required: true }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Register
        </Button>
        <Grid container>
          <Grid item>
            <Link component="button" onClick={() => history.push('/login')}>
              Sign In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
