import { IBudget, IBudgetPeriod } from '../../common/entities';

function isActivePeriod(period: IBudgetPeriod) {
  const currDate = new Date();
  return (
    period.year === currDate.getFullYear() &&
    period.month === currDate.getMonth()
  );
}

export function hasActivePeriod(budget: IBudget) {
  return budget.periods && budget.periods.some((p) => isActivePeriod(p));
}

export function getActivePeriod(budget: IBudget) {
  const savedPeriod = budget.periods?.find((p) => isActivePeriod(p));
  return savedPeriod ?? getCurrentPeriod(budget);
}

export function getMonthPeriod(budget: IBudget, month: Date): IBudgetPeriod | undefined {
  return budget.periods?.find(p => p.year === month.getFullYear() && p.month === month.getMonth());
}

export function getCurrentPeriod(budget: IBudget): IBudgetPeriod {
  const currDate = new Date();
  return {
    year: currDate.getFullYear(),
    month: currDate.getMonth(),
    budget: budget.defaultBudget
  }
}

export function getMonthBudgetTotal(budgets: IBudget[], month: Date) {
  return budgets.reduce(
    (sum, current) => {
      const currentPeriod = getMonthPeriod(current, month);
      return sum + (currentPeriod?.budget ?? 0);
    },
    0
  );
}
