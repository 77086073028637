import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategory, IUserData } from '../../../common/entities';
import {
  addCategory,
  deleteCategory,
  getCategory,
  listCategories,
  updateCategory
} from '../actions/categories.actions';
import { getUserData } from '../actions/userData.actions';

interface ICategoriesState {
  categories: ICategory[];
  isLoading: boolean;
  error?: string;
  lastAction: string;
  currentCategory?: ICategory;
}

const initialState: ICategoriesState = {
  categories: [],
  isLoading: false,
  error: '',
  lastAction: '',
  currentCategory: undefined
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: { ...initialState },
  reducers: {
    resetCategories(state, action: PayloadAction) {
      state.categories = [];
      state.isLoading = false;
      state.error = '';
      state.lastAction = '';
      state.currentCategory = undefined;
    }
  },
  extraReducers: {
    [getUserData.fulfilled.type]: (state, action: PayloadAction<IUserData>) => {
      state.isLoading = false;
      state.error = undefined;
      state.categories = action.payload.categories;
    },
    [listCategories.fulfilled.type]: (
      state,
      action: PayloadAction<ICategory[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.categories = action.payload;
      state.lastAction = action.type;
    },
    [listCategories.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = state.lastAction = action.type;
    },
    [listCategories.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [getCategory.fulfilled.type]: (state, action: PayloadAction<ICategory>) => {
      state.isLoading = false;
      state.error = '';
      state.currentCategory = action.payload;
      state.lastAction = action.type;
    },
    [getCategory.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = state.lastAction = action.type;
    },
    [getCategory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [addCategory.fulfilled.type]: (state, action: PayloadAction<ICategory>) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [addCategory.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [addCategory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [updateCategory.fulfilled.type]: (
      state,
      action: PayloadAction<ICategory>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [updateCategory.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [updateCategory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [deleteCategory.fulfilled.type]: (
      state,
      action: PayloadAction<ICategory>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [deleteCategory.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [deleteCategory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    }
  }
});

export const { resetCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
