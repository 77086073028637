import {
  confirmEmail,
  login,
  LoginPayloadAction,
  register,
  RegisterPayloadAction,
  sendConfirmEmail
} from '../actions/auth.actions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, IUserData, IUserPreferences } from '../../../common/entities';
import {
  getPreferences,
  updatePreferences
} from '../actions/preferences.actions';
import { getUserData } from '../actions/userData.actions';

interface IUserState {
  user: IUser | undefined;
  preferences: IUserPreferences;
  isLoading: boolean;
  error?: string;
  errorStatus?: string;
  registeredUserId?: string;
  shouldConfirmEmail?: string;
  hasLoaded: boolean;
  lastAction?: string;
}

const initialState: IUserState = {
  user: undefined,
  preferences: {
    expenseDayBudgetsSummary: []
  },
  shouldConfirmEmail: '',
  hasLoaded: false,
  isLoading: false,
  error: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    initUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.shouldConfirmEmail = !action.payload.emailConfirmed
        ? action.payload.email
        : '';
    },
    resetUser(state) {
      state.user = undefined;
    },
    resetErrors(state) {
      state.error = undefined;
      state.errorStatus = undefined;
    },
    resetRegistration(state) {
      state.registeredUserId = undefined;
      state.error = undefined;
      state.errorStatus = undefined;
    }
  },
  extraReducers: {
    [login.fulfilled.type]: (state, action: LoginPayloadAction) => {
      state.isLoading = false;
      state.error = '';
      state.user = {
        profileId: action.payload.sub,
        email: action.payload.email,
        emailConfirmed: action.payload.emailConfirmed
      };
      state.shouldConfirmEmail = !action.payload.emailConfirmed
        ? action.payload.email
        : '';
    },
    [login.pending.type]: state => {
      state.isLoading = true;
    },
    [login.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [register.fulfilled.type]: (state, action: RegisterPayloadAction) => {
      state.shouldConfirmEmail = action.meta.arg.email;
      state.registeredUserId = action.payload;
      state.isLoading = false;
      state.error = '';
      state.errorStatus = undefined;
    },
    [register.pending.type]: state => {
      state.isLoading = true;
    },
    [register.rejected.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload.message;
      state.errorStatus = action.payload.customStatus ?? action.payload.status;
    },
    [getUserData.fulfilled.type]: (state, action: PayloadAction<IUserData>) => {
      state.isLoading = false;
      state.error = undefined;
      state.preferences = action.payload.preferences;
      state.hasLoaded = true;
    },
    [getPreferences.fulfilled.type]: (
      state,
      action: PayloadAction<IUserPreferences>
    ) => {
      state.preferences = action.payload;
      state.isLoading = false;
      state.error = '';
      state.errorStatus = undefined;
      state.hasLoaded = true;
      state.lastAction = undefined;
    },
    [getPreferences.pending.type]: state => {
      state.isLoading = true;
    },
    [getPreferences.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updatePreferences.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = '';
      state.errorStatus = undefined;
      state.lastAction = action.type;
    },
    [updatePreferences.pending.type]: state => {
      state.isLoading = true;
    },
    [updatePreferences.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [sendConfirmEmail.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = '';
      state.errorStatus = undefined;
      state.lastAction = action.type;
    },
    [sendConfirmEmail.pending.type]: state => {
      state.isLoading = true;
    },
    [sendConfirmEmail.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [confirmEmail.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = '';
      state.errorStatus = undefined;
      state.lastAction = action.type;
    },
    [confirmEmail.pending.type]: state => {
      state.isLoading = true;
    },
    [confirmEmail.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    }
  }
});

export const { resetUser, resetErrors, resetRegistration, initUser } =
  userSlice.actions;
export default userSlice.reducer;
