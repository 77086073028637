import { compareDate, compareMonth } from 'utils/dateUtils';
import { IExpense } from '../../common/entities';

export function getBudgetMonthSpent(
  budgetId: string | undefined,
  expenses: IExpense[],
  month: Date
) {
  return expenses.reduce((sum, current) => {
    return current.budgetId === budgetId && isExpenseInMonth(current, month)
      ? sum + current.amount
      : sum;
  }, 0);
}

export function getBudgetSpent(
  budgetId: string | undefined,
  expenses: IExpense[]
) {
  return expenses.reduce((sum, current) => {
    return current.budgetId === budgetId ? sum + current.amount : sum;
  }, 0);
}

export function getCategorySpent(
  categoryId: string | undefined,
  expenses: IExpense[],
  month: Date
) {
  return expenses.reduce((sum, current) => {
    return current.categoryId === categoryId && isExpenseInMonth(current, month)
      ? sum + current.amount
      : sum;
  }, 0);
}

export function isExpenseInMonth(expense: IExpense, month: Date) {
  const expenseDate = new Date(expense.date);
  return compareMonth(expenseDate, month);
}

export function getExpenseMonthTotal(expenses: IExpense[], month: Date) {
  return expenses.reduce((sum, current) => {
    return isExpenseInMonth(current, month) ? sum + current.amount : sum;
  }, 0);
}

export function getExpenseDayTotal(expenses: IExpense[], date: Date) {
  return expenses.reduce((sum, current) => {
    return compareDate(new Date(current.date), date)
      ? sum + current.amount
      : sum;
  }, 0);
}
