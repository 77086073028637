import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { Component, FC, FocusEvent, useEffect, useState } from 'react';

interface EditableCellProps {
  initialValue: number;
  onSaveValue?: (value: number) => void;
  renderValue?: (value: number) => JSX.Element;
}

const EditableNumberCell: FC<EditableCellProps> = ({ initialValue, onSaveValue, renderValue }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [value, setValue] = useState<number>(initialValue);
  const [stringValue, setStringValue] = useState<string>(initialValue.toString())

  const setInitial = () => {
    setValue(initialValue);
    setStringValue(initialValue.toString());
  }

  useEffect(() => {
    setInitial();
  }, [initialValue]);

  const handleChange = (event) => {
    setStringValue(event.target.value);
    if (!isNaN(event.target.valueAsNumber)) {
      setValue(event.target.valueAsNumber)
    }
  };

  const handleSaveChanges = () => {
    if (!isNaN(value)) {
      onSaveValue?.(value);
    } else {
      setInitial();
    }
    setIsEdit(false);
  }; 

  const handleExit = (event: FocusEvent) => {
    event.stopPropagation();
    if (event.relatedTarget?.ariaLabel !== 'save') {
      setIsEdit(false);
      setInitial();
    }
  };

  return isEdit ? (
    <Box>
      <TextField
        sx={{ width: '80px' }}
        type="number"
        variant="standard"
        value={stringValue}
        onChange={handleChange}
        onBlur={handleExit}
      />
      <IconButton
        id="test"
        aria-label="save"
        size="small"
        sx={{ p: 0 }}
        onClick={handleSaveChanges}
      >
        <DoneIcon fontSize="inherit" />
      </IconButton>
    </Box>
  ) : (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      {value ? (
        <Typography component="span" sx={{ marginRight: '10px' }}>
          {renderValue ? renderValue(value) : value}
        </Typography>
      ) : (
        <Typography
          component="span"
          sx={{ fontSize: '0.8em', color: '#999', marginRight: '10px' }}
        >
          &lt;not set&gt;
        </Typography>
      )}

      <IconButton
        aria-label="edit"
        size="small"
        sx={{ p: 0 }}
        onClick={() => setIsEdit(true)}
      >
        <EditIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

export default EditableNumberCell;
