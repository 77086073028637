import { Box, Button, Skeleton } from '@mui/material';
import { renderMenuItem } from 'components/utils/selectOptions';
import { useNotificationContext } from 'core/providers/NotificationContextProvider';
import { listBudgets } from 'core/store/actions/budgets.actions';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { FC, useEffect, useMemo } from 'react';
import { ICategory, ICategoryBase } from '../../../common/entities';
import { IFormState, IValidationState } from '../../../common/types';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/hooks';
import { useEntityEditForm } from '../../../core/hooks/useEntityEditForm';
import {
  addCategory,
  getCategory,
  updateCategory
} from '../../../core/store/actions/categories.actions';
import { categoriesSelector } from '../../../core/store/selectors/categories.selector';
import RightDrawer from '../../BaseLayout/RightDrawer';
import { Selector, TextField } from '../../controls/formControls';

export interface IEditCategoryForm {
  title: string;
  categoryId?: string;
  isOpen: boolean;
  closePanel: () => void;
}

const initialState: IFormState = {
  id: { value: '' },
  defaultBudget: { value: '' },
  parentCategory: { value: '' },
  name: { value: '' }
};

const EditCategoryForm: FC<IEditCategoryForm> = ({
  title,
  categoryId,
  isOpen,
  closePanel
}) => {
  const { showNotification } = useNotificationContext();
  const dispatch = useAppDispatch();
  const { lastAction, error, isLoading } = useAppSelector(categoriesSelector);
  const { budgets } = useAppSelector(budgetsSelector);
  const { categories } = useAppSelector(categoriesSelector);
  const currentCategory = categories.find(c => c.id === categoryId);

  const parentCategories = useMemo(
    () =>
      categories.filter(cat => cat.id !== categoryId && !cat.parentCategoryId),
    [categories, categoryId]
  );

  const isParent = useMemo(
    () => categoryId && categories.some(c => c.parentCategoryId === categoryId),
    [categories, categoryId]
  );

  const validationSchema: IValidationState = {
    name: {
      required: true
    }
  };

  const { formState, updateEntityProperty, handleSubmit } =
    useEntityEditForm<ICategory>(
      categoryId,
      initialState,
      currentCategory,
      isOpen,
      createFormState,
      addCategoryAction,
      updateCategoryAction,
      undefined,
      validationSchema
    );

  const budgetOptions: JSX.Element[] = useMemo(
    () => [
      renderMenuItem('', ''),
      ...budgets
        .filter(b => !b.accumulation && !b.isDeleted)
        .map(b => renderMenuItem(b.id, b.name))
    ],
    [budgets]
  );

  const parentCategoryOptions: JSX.Element[] = useMemo(
    () => [
      renderMenuItem('', ''),
      ...parentCategories.map(c => renderMenuItem(c.id, c.name))
    ],
    [parentCategories]
  );

  if (isOpen && !budgets?.length) {
    dispatch(listBudgets());
  }

  useEffect(() => {
    switch (lastAction) {
      case addCategory.fulfilled.type:
        closePanel();
        showNotification({
          message: `Category "${formState.name.value}" has been added`
        });
        break;
      case addCategory.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
      case getCategory.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        closePanel();
        break;
      case updateCategory.fulfilled.type:
        closePanel();
        showNotification({
          message: `Category "${formState.name.value}" has been updated`
        });
        break;
      case updateCategory.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  const handleInputChange = event => {
    updateEntityProperty(event.target.name, event.target.value);
  };

  function createFormState(category: ICategory): IFormState {
    return {
      defaultBudget: { value: category.defaultBudget ?? '' },
      parentCategory: { value: category.parentCategoryId ?? '' },
      name: { value: category.name }
    };
  }

  function createCategory(): ICategoryBase {
    return {
      defaultBudget: formState.defaultBudget.value,
      parentCategoryId: formState.parentCategory.value,
      name: formState.name.value
    };
  }

  function addCategoryAction() {
    return addCategory(createCategory());
  }

  function updateCategoryAction() {
    if (!categoryId) return;
    return updateCategory({
      id: categoryId,
      category: createCategory()
    });
  }

  const styles = {
    width: '100%'
  };

  return (
    <RightDrawer title={title} isOpen={isOpen} closePanel={closePanel}>
      {!isLoading && (
        <Box component="form" sx={styles} onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            value={formState.name.value}
            handleChange={handleInputChange}
            title="Name"
            error={formState.name.error}
          />
          {!isParent && (
            <Selector
              id="parentCategory"
              name="parentCategory"
              label="Parent category"
              handleChange={handleInputChange}
              value={formState.parentCategory.value}
            >
              {parentCategoryOptions}
            </Selector>
          )}
          <Selector
            id="defaultBudget"
            name="defaultBudget"
            label="Default budget"
            handleChange={handleInputChange}
            value={formState.defaultBudget.value}
          >
            {budgetOptions}
          </Selector>
          <Box
            sx={{ display: 'flex', justifyContent: 'right', marginTop: '30px' }}
          >
            <Button type="submit">
              {categoryId ? 'Update category' : 'Add category'}
            </Button>
          </Box>
        </Box>
      )}
      {isLoading && (
        <Skeleton variant="rectangular" height="80vh" width="600px" />
      )}
    </RightDrawer>
  );
};

export default EditCategoryForm;
