import { IToken } from 'common/types';
import { jwtDecode } from 'jwt-decode';

export function getTokenInfo(token: string): IToken | null {
  try {
    const tokenInfo = jwtDecode<IToken>(token);
    return tokenInfo;
  } catch (error) {
    console.log('jwt_decode err', error);
    return null;
  }
}
