import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IBudget } from '../../../common/entities';

export interface IBudgetListItem {
  budget: IBudget;
  onMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    expenseId: string
  ) => void;
}

const sx = {
  width: '100%',
};

export const BudgetListItem: FC<IBudgetListItem> = ({
  budget,
  onMenuClick,
}: IBudgetListItem) => {
  const itemContent = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h5" sx={{ marginRight: '10px' }}>
        {budget.name}
      </Typography>
      {budget.accumulation ? (
        <Chip label="Accumulation" color="primary" size="small" />
      ) : (
        <Chip label="Budget" color="success" size="small" />
      )}
    </Box>
  );

  return (
    <Card sx={sx}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={(e) => onMenuClick(e, budget.id)}
          >
            <MoreVert />
          </IconButton>
        }
        title={
          !budget.accumulation ? (
            <Link
              to={{ pathname: `/budget/${budget.id}`, state: { goBackTitle: 'Back to budgets' }  }}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {itemContent}
            </Link>
          ) : (
            <>{itemContent}</>
          )
        }
      />
    </Card>
  );
};
