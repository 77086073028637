import { useState } from 'react';

export interface IEntityContext<T> {
  current: T | undefined;
  set: (T) => void;
  reset: () => void;
}

export function useEntityContext<T>(): IEntityContext<T> {
  const [target, setTarget] = useState<T>();

  function set(target: T) {
    setTarget(target);
  }

  function reset() {
    setTarget(undefined);
  }

  return { current: target, set, reset };
}
