import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { ITableColumn } from 'common/types';
import React, { useCallback } from 'react';

export interface TableSxProps {
  cell?: SxProps;
  headCell?: SxProps;
}

export interface ISortableTable<TRow> {
  columns: ITableColumn<TRow>[];
  rows: TRow[];
  indexBy: keyof TRow;
  defaultOrderBy?: keyof TRow;
  customCellBuilder?: (column: keyof TRow, row: TRow) => JSX.Element;
  styles?: TableSxProps;
}

function descendingComparator<T>(a: T, b: T, orderBy?: keyof T) {
  if (orderBy && b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (orderBy && b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<TRow>(
  order: Order,
  orderBy?: keyof TRow
): (a: TRow, b: TRow) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps<TRow> {
  columns: ITableColumn<TRow>[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TRow
  ) => void;
  order: Order;
  orderBy?: keyof TRow;
  sx?: SxProps;
}

function getAlign<TRow>(c: ITableColumn<TRow>) {
  return c.align ?? (c.numeric ? 'right' : 'left');
}

function TableHeader<TRow>(props: EnhancedTableProps<TRow>) {
  const { columns, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof TRow) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id.toString()}
            align={getAlign(column)}
            sortDirection={orderBy === column.id ? order : false}
            sx={props.sx}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SortableTable<TRow>({
  columns,
  rows,
  indexBy,
  defaultOrderBy,
  customCellBuilder,
  styles
}: ISortableTable<TRow>) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TRow | undefined>(defaultOrderBy);

  const handleRequestSort = (_, property: keyof TRow) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle" size="medium">
        <TableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          sx={styles?.headCell}
        />
        <TableBody>
          {rows
            .slice()
            .sort(getComparator(order, orderBy))
            .map((row) => {
              const rowKey = (row[indexBy] as any).toString();

              return (
                <TableRow hover tabIndex={-1} key={rowKey}>
                  {columns.map((c) => (
                    <TableCell key={c.id.toString()} align={getAlign(c)} sx={styles?.cell}>
                      {customCellBuilder
                        ? customCellBuilder(c.id, row)
                        : row[c.id]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
