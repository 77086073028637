import { Box } from '@mui/system';
import { routes } from 'common/constants';
import BackToButton from 'components/Shared/BackToButton';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import { getUserData } from 'core/store/actions/userData.actions';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { periodSelector } from 'core/store/selectors/period.selector';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Budget from './Budget';

const BudgetPage: FC = () => {
  const month = useAppSelector(periodSelector('budget'));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { budgetId } = useParams<{ budgetId: string }>();
  const { budgets } = useAppSelector(budgetsSelector);
  const budget = useMemo(
    () => budgets.find((b) => b.id === budgetId),
    [budgetId, budgets]
  );

  useEffect(() => {
    !budgets?.length && dispatch(getUserData());
  }, []);

  useEffect(() => {
    budgetId && budgets?.length && !budget && history.push(routes.home);
  }, [budgetId, budgets, budget]);

  return (
    <Box sx={{ pt: 1, pb: 9 }}>
      <BackToButton />
      <Box>
        {budget && (
          <Budget
            budgetMonth={month}
            budget={budget}
          />
        )}
      </Box>
    </Box>
  );
};

export default BudgetPage;
