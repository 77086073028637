import {
  Box,
  Grid, Typography
} from '@mui/material';
import { settingPages } from 'common/constants';
import { NotificationContextProvider } from 'core/providers/NotificationContextProvider';
import { FC, useState } from 'react';
import Categories from './Categories/Categories';
import Preferences from './Preferences';
import Profile from './Profile/Profile';
import { SettingsNav } from './SettingsNav';

const Settings: FC = () => {
  const [page, setPage] = useState<string>(settingPages.categories);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Box sx={{ pb: 2, pt: 1 }}>
          <Typography variant="h2">Settings</Typography>
        </Box>
        <SettingsNav
          currentPage={page}
          setPage={(page: string) => setPage(page)}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Box sx={{ display: page === settingPages.profile ? 'block' : 'none' }}>
          <Profile />
        </Box>
        <Box
          sx={{
            display: page === settingPages.categories ? 'block' : 'none'
          }}
        >
          <Categories />
        </Box>
        <Box
          sx={{
            display: page === settingPages.preferences ? 'block' : 'none'
          }}
        >
          <Preferences />
        </Box>
      </Grid>
    </Grid>
  );
};

const SettingsWithNotifications = () => (
  <NotificationContextProvider>
    <Settings />
  </NotificationContextProvider>
)

export default SettingsWithNotifications;
