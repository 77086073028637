import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useNotificationContext } from 'core/providers/NotificationContextProvider';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { FC, useCallback, useEffect } from 'react';
import { IBudget } from '../../../common/entities';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/hooks';
import { useContextMenu } from '../../../core/hooks/useContextMenu';
import { IEntityContext } from '../../../core/hooks/useEntityContext';
import { deleteBudget } from '../../../core/store/actions/budgets.actions';
import { BudgetListItem } from './BudgetCard';

export interface IBudgetList {
  context: IEntityContext<string>;
  openEditForm: () => void;
  budgets: IBudget[];
}

const BudgetList: FC<IBudgetList> = ({ context, openEditForm, budgets }) => {
  const { showNotification } = useNotificationContext();
  const dispatch = useAppDispatch();
  const contextMenu = useContextMenu(context);
  const { lastAction, error } = useAppSelector(budgetsSelector);

  useEffect(() => {
    switch (lastAction) {
      case deleteBudget.fulfilled.type:
        showNotification({
          message: `Budget has been deleted`
        });
        break;
      case deleteBudget.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  const onOpenEditForm = () => {
    contextMenu.hideMenu();
    openEditForm();
  };

  const renderItemMenu = useCallback(() => {
    const handleDeleteBudget = () => {
      context.current && dispatch(deleteBudget(context.current));
      contextMenu.closeMenu();
    };

    return (
      <Menu
        open={contextMenu.menuOpen}
        anchorEl={contextMenu.anchorEl}
        onClose={contextMenu.closeMenu}
      >
        <MenuItem onClick={onOpenEditForm}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteBudget}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    );
  }, [context]);

  return (
    <>
      <List>
        {budgets.map(b => (
          <ListItem sx={{ p: 0, mb: 2 }} key={b.id}>
            <BudgetListItem budget={b} onMenuClick={contextMenu.menuClick} />
          </ListItem>
        ))}
      </List>
      {renderItemMenu()}
    </>
  );
};

export default BudgetList;
