import { Chip, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { emptyCells } from 'common/constants';
import { IBudget } from 'common/entities';
import { ITableColumn } from 'common/types';
import { MonthSelector } from 'components/Shared/MonthSelector';
import SortableTable from 'components/Shared/SortableTable';
import { useAppSelector } from 'core/hooks/hooks';
import { sumWithCurrency } from 'core/services/currency.service';
import { isExpenseInMonth } from 'core/services/expense.service';
import { categoriesSelector } from 'core/store/selectors/categories.selector';
import { expensesSelector } from 'core/store/selectors/expenses.selector';
import { FC, useMemo, useState } from 'react';

export interface IBudgetProps {
  budget: IBudget;
  budgetMonth: Date;
  background?: boolean;
}

interface IBudgetExpenseRow {
  id: string;
  category: string;
  date: Date;
  description: string;
  amount: number;
}

const expensesColumns: ITableColumn<IBudgetExpenseRow>[] = [
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'right',
  },
];

const customCellBuilder = (
  column: keyof IBudgetExpenseRow,
  row: IBudgetExpenseRow
): JSX.Element => {
  switch (column) {
    case 'date':
      return (
        <>
          {new Date(row.date).toLocaleDateString('uk-UA', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })}
        </>
      );
    case 'description':
      return row.description ? (
        <>{row.description}</>
      ) : (
        <Typography component="span" sx={{ color: '#555' }}>
          {emptyCells.description}
        </Typography>
      );
    case 'amount':
      return <>{sumWithCurrency(row.amount)}</>;
    default:
      return <>{row[column]}</>;
  }
};

const Budget: FC<IBudgetProps> = ({
  budgetMonth,
  budget,
}: IBudgetProps) => {
  const { expenses } = useAppSelector(expensesSelector('budget'));
  const budgetExpenses = useMemo(
    () =>
      expenses.filter(
        (e) => e.budgetId === budget.id && isExpenseInMonth(e, budgetMonth)
      ),
    [budget, expenses, budgetMonth]
  );
  const { categories } = useAppSelector(categoriesSelector);

  const expensesRows = useMemo(
    () =>
      budgetExpenses
        .sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()) * -1)
        .map(
          (e): IBudgetExpenseRow => ({
            id: e.id,
            category:
              categories.find((c) => c.id === e.categoryId)?.name ??
              emptyCells.category,
            date: e.date,
            amount: e.amount,
            description: e.description ?? emptyCells.description,
          })
        ),
    [categories, budgetExpenses]
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ mr: 4 }}>
          <Chip
            label="Budget"
            size="small"
            sx={{ mr: 1 }}
            variant="outlined"
            color="success"
          />{' '}
          {budget.name}
        </Typography>
        <MonthSelector target='budget' />
      </Box>

      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle1">Budget expenses</Typography>
        <SortableTable
          columns={expensesColumns}
          rows={expensesRows}
          indexBy="id"
          customCellBuilder={customCellBuilder}
        />
      </Paper>
    </Box>
  );
};

export default Budget;
