import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper
} from '@mui/material';
import { settingPages } from 'common/constants';
import { FC } from 'react';

interface ISettingsNav {
  currentPage: string;
  setPage: (page: string) => void;
}

interface ISettingNavItem {
  setPage: (page: string) => void;
  selected: boolean;
  page: string;
  title: string;
}

const SettingNavItem: FC<ISettingNavItem> = ({
  title,
  page,
  selected,
  setPage
}) => (
  <ListItem key={title} disablePadding>
    <ListItemButton
      onClick={() => setPage(page)}
      sx={{ backgroundColor: selected ? 'rgba(0, 0, 0, 0.04)' : undefined }}
    >
      <ListItemText primary={title} />
    </ListItemButton>
  </ListItem>
);

export const SettingsNav: FC<ISettingsNav> = ({ currentPage, setPage }) => {
  return (
    <Paper>
      <List component="nav">
        <SettingNavItem
          title="Profile"
          page={settingPages.profile}
          selected={currentPage === settingPages.profile}
          setPage={setPage}
        />
        <SettingNavItem
          title="Categories"
          page={settingPages.categories}
          selected={currentPage === settingPages.categories}
          setPage={setPage}
        />
        <SettingNavItem
          title="Preferences"
          page={settingPages.preferences}
          selected={currentPage === settingPages.preferences}
          setPage={setPage}
        />
      </List>
    </Paper>
  );
};
