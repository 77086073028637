import { createContext, FC, useContext, useMemo, useState } from 'react';
import { INotification } from 'common/types';

export type NotificationContextType = {
  notification: INotification | undefined;
  isNotificationOpen: boolean;
  showNotification: (notification: INotification) => void;
  onCloseNotification: (event: any, reason?: string) => void;
};

export const NotificationContext =
  createContext<NotificationContextType | null>(null);

export const NotificationContextProvider: FC = ({ children }) => {
  const [notification, setNotification] = useState<INotification>();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const showNotification = (notification: INotification) => {
    setNotification(notification);
    setIsNotificationOpen(true);
  };

  const onCloseNotification = (_: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsNotificationOpen(false);
  };

  return useMemo(
    () => (
      <NotificationContext.Provider
        value={{
          notification,
          isNotificationOpen,
          showNotification,
          onCloseNotification,
        }}
      >
        {children}
      </NotificationContext.Provider>
    ),
    [children, notification, isNotificationOpen]
  );
};

export const useNotificationContext = () => useContext(NotificationContext) as NotificationContextType;
