import SavingsIcon from '@mui/icons-material/Savings';
import {
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled
} from '@mui/material';
import { routes } from 'common/constants';
import BottomNavigation from 'components/mobile/BottomNavigation';
import { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileMenu } from './Header/ProfileMenu';

const styles = {
  container: {
    pt: 2,
    pb: 2,

    maxWidth: {
      xs: 'auto',
      md: 'calc(100% - 480px) !important'
    }
  },
  sidebar: {
    display: {
      xs: 'none',
      sm: 'block'
    },
    width: 240,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: 240,
      boxSizing: 'border-box',
      background: 'transparent',
      boxShadow: 'rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem',
      p: '20px',
      borderRight: 'none',
      justifyContent: 'space-between'
    }
  }
};

export interface BaseLayoutProps {
  showNavigation?: boolean;
}

const MenuItem = styled(ListItem)(({ theme }) => ({
  background: '#fff',
  borderRadius: '1rem',
  marginBottom: '0.5rem',
  overflow: 'hidden',
  '&:hover': {
    background: '#fefaf4'
  }
}));

function Menu() {
  const history = useHistory();
  return (
    <>
      <Box sx={{ pt: '20px' }} />
      <List component="nav">
        <MenuItem key="Expenses" disablePadding>
          <ListItemButton onClick={() => history.push(routes.home)}>
            <ListItemText primary="Expenses" />
          </ListItemButton>
        </MenuItem>
        <MenuItem key="Budgets" disablePadding>
          <ListItemButton onClick={() => history.push(routes.budgets)}>
            <ListItemText primary="Budgets" />
          </ListItemButton>
        </MenuItem>
      </List>
    </>
  );
}

export function SidebarLogo() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        variant="outlined"
        startIcon={<SavingsIcon />}
        sx={{ flexGrow: 1 }}
      >
        costman
      </Button>
    </Box>
  );
}

export function MainSidebar() {
  return (
    <Drawer variant="permanent" open={false} sx={styles.sidebar}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <SidebarLogo />
        <Menu />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'left' }}>
        <ProfileMenu />
      </Box>
    </Drawer>
  );
}

export default function BaseLayout({
  showNavigation = true,
  children
}: PropsWithChildren<BaseLayoutProps>) {
  return (
    <Box>
      <main className="content">
        <Container sx={styles.container}>{children}</Container>
      </main>
      {showNavigation && (
        <>
          <MainSidebar />
          <BottomNavigation />
        </>
      )}
    </Box>
  );
}
