import { createAsyncThunk } from '@reduxjs/toolkit';
import { IMonth } from 'common/types';
import { IExpense } from '../../../common/entities';
import axios from '../../api/axiosClient';
import { endpoints } from '../../api/endpoints';
import { IExpensesState } from '../reducers/expenses.reducer';

export const listExpenses = createAsyncThunk(
  'expense/list',
  async (month: IMonth | undefined, thunkAPI) => {
    try {
      const response = await axios.get<IExpense[]>(endpoints.expense, {
        params: { month: month?.month, year: month?.year }
      });
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getExpense = createAsyncThunk(
  'expense/get',
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IExpense>(`${endpoints.expense}/${id}`);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const addExpense = createAsyncThunk(
  'expense/add',
  async (data: Partial<IExpense>, thunkAPI) => {
    try {
      const response = await axios.post(endpoints.expense, data);
      const targetMonth = (
        thunkAPI.getState() as { expensesReducer: IExpensesState }
      ).expensesReducer.currentFilter;
      thunkAPI.dispatch(listExpenses(targetMonth));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const updateExpense = createAsyncThunk(
  'expense/update',
  async (data: { id: string; expense: Partial<IExpense> }, thunkAPI) => {
    const { id, expense } = data;
    try {
      const response = await axios.put(`${endpoints.expense}/${id}`, expense);
      thunkAPI.dispatch(listExpenses());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const deleteExpense = createAsyncThunk(
  'expense/delete',
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${endpoints.expense}/${id}`);
      thunkAPI.dispatch(listExpenses());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);
