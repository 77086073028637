import { Box, Paper, Typography } from '@mui/material';
import { emptyCells } from 'common/constants';
import { ITableColumn } from 'common/types';
import { MonthSelector } from 'components/Shared/MonthSelector';
import SortableTable from 'components/Shared/SortableTable';
import EditableNumberCell from 'components/table/EditableNumberCell';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import { useNotificationContext } from 'core/providers/NotificationContextProvider';
import { sumWithCurrency } from 'core/services/currency.service';
import {
  getBudgetMonthSpent,
  getExpenseMonthTotal
} from 'core/services/expense.service';
import { setBudgetMonth } from 'core/store/actions/budgets.actions';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { expensesSelector } from 'core/store/selectors/expenses.selector';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { IBudget, IBudgetPeriod, IExpense } from '../../../common/entities';
import {
  getMonthBudgetTotal,
  getMonthPeriod
} from '../../../core/services/budget.service';
import { BudgetMonthSummary } from './BudgetMonthSummary';

export interface IBudgetMonthProps {
  budgets: IBudget[];
  budgetMonth: Date;
}

interface IMonthBudgetRecord {
  id: string;
  name: string;
  budgetSum?: number;
  spent?: number;
  balance?: number;
}

interface IMonthAccumulationRecord {
  id: string;
  name: string;
  target?: number;
  monthBudget?: number;
}

const budgetColumns: ITableColumn<IMonthBudgetRecord>[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Budget',
    editable: false,
  },
  {
    id: 'budgetSum',
    numeric: true,
    label: 'Month budget',
    align: 'right',
    editable: true,
  },
  {
    id: 'spent',
    numeric: true,
    label: 'Spent',
    editable: false,
  },
  {
    id: 'balance',
    numeric: true,
    label: 'Balance',
    editable: false,
  },
];

const accumulationsColumns: ITableColumn<IMonthAccumulationRecord>[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Accumulation',
  },
  {
    id: 'target',
    numeric: true,
    label: 'Target',
  },
  {
    id: 'monthBudget',
    numeric: true,
    label: 'Month budget',
  },
];

function createBudgetRow(
  budget: IBudget | undefined,
  expenses: IExpense[],
  monthDate: Date
): IMonthBudgetRecord {
  const budgetMonth = budget ? getMonthPeriod(budget, monthDate) : undefined;
  const spent = getBudgetMonthSpent(budget?.id, expenses, monthDate);
  return {
    id: budget?.id ?? '0',
    name: budget?.name ?? emptyCells.budget,
    budgetSum: budgetMonth?.budget ?? 0,
    spent,
    balance: (budgetMonth?.budget ?? 0) - spent,
  };
}

function createAccumulationRow(budget: IBudget, monthDate: Date): IMonthAccumulationRecord {
  const budgetMonth = budget ? getMonthPeriod(budget, monthDate) : undefined;
  return {
    id: budget.id,
    name: budget.name,
    target: budget.accumulationGoal,
    monthBudget: budgetMonth?.budget,
  };
}

function createRows(budgets: IBudget[], expenses: IExpense[], monthDate: Date) {
  const budgetRows: IMonthBudgetRecord[] = [
    createBudgetRow(undefined, expenses, monthDate),
  ].concat(
    budgets
      .filter((b) => !b.accumulation)
      .map((b) => createBudgetRow(b, expenses, monthDate))
  );
  const accumulationRows = budgets
    .filter((b) => b.accumulation)
    .map((b) => createAccumulationRow(b, monthDate));
  return { budgetRows, accumulationRows };
}

const CurrentBudgetMonth: FC<IBudgetMonthProps> = ({
  budgets,
  budgetMonth
}) => {
  const { lastAction, error } = useAppSelector(budgetsSelector);
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationContext();
  const { expenses } = useAppSelector(expensesSelector('budgetMonth'));
  const { budgetRows, accumulationRows } = useMemo(
    () => createRows(budgets, expenses, budgetMonth),
    [budgets, expenses, budgetMonth]
  );
  const totalMonthBudget = useMemo(
    () => getMonthBudgetTotal(budgets, budgetMonth),
    [budgets, budgetMonth]
  );

  const totalMonthBudgetSpent = useMemo(
    () => getExpenseMonthTotal(expenses, budgetMonth),
    [expenses, budgetMonth]
  );

  const totalMonthAccumulationSpent = useMemo(
    () =>
      getMonthBudgetTotal(
        budgets.filter((b) => b.accumulation),
        budgetMonth
      ),
    []
  );

  const currentBudgetBalance = useMemo(
    () => totalMonthBudget - totalMonthBudgetSpent,
    [totalMonthBudget, totalMonthBudgetSpent]
  );

  useEffect(() => {
    switch (lastAction) {
      case setBudgetMonth.fulfilled.type:
        showNotification({
          message: `Month budget has been updated`,
        });
        break;
      case setBudgetMonth.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  const createBudgetMonth = useCallback(
    (budgetSum: number | undefined): IBudgetPeriod => ({
      month: budgetMonth.getMonth(),
      year: budgetMonth.getFullYear(),
      budget: budgetSum,
    }),
    [budgetMonth]
  );

  const handleUpdateBudgetMonthSum = useCallback(
    (budgetId: string, budgetMonth: IBudgetPeriod) => {
      dispatch(
        setBudgetMonth({
          budgetId,
          budgetMonth,
        })
      );
    },
    [dispatch]
  );

  const renderBudgetSumItem = (value: any) => <>{sumWithCurrency(value)}</>;

  const budgetsCustomCellBuilder = useMemo(
    () =>
      (
        column: keyof IMonthBudgetRecord,
        row: IMonthBudgetRecord
      ): JSX.Element => {
        switch (column) {
          case 'name':
            return row.id === '0' ? (
              <Typography
                component={'span'}
                sx={{ color: '#555', fontSize: '11px' }}
              >
                {row.name}
              </Typography>
            ) : (
              <Link
                to={{
                  pathname: `/budget/${row.id}`,
                  state: { goBackTitle: 'Back to budgets' },
                }}
                style={{ color: theme.palette.common.black }}
              >
                {row.name}
              </Link>
            );
          case 'budgetSum':
            return row.id === '0' ? (
              <Typography component={'span'} sx={{ marginRight: '25px' }}>
                -
              </Typography>
            ) : (
              <EditableNumberCell
                initialValue={Number(row.budgetSum) ?? 0}
                onSaveValue={(value: number) =>
                  handleUpdateBudgetMonthSum(row.id, createBudgetMonth(value))
                }
                renderValue={renderBudgetSumItem}
              />
            );
          case 'spent':
            return <>{sumWithCurrency(row['spent'] ?? 0)}</>;
          case 'balance':
            return row.id === '0' ? (
              <>-</>
            ) : (
              <>{sumWithCurrency(row['balance'] ?? 0)}</>
            );
          default:
            return <>{row[column]}</>;
        }
      },
    [handleUpdateBudgetMonthSum, createBudgetMonth]
  );

  const accumulationsCustomCellsBuilder = (
    column: keyof IMonthAccumulationRecord,
    row: IMonthAccumulationRecord
  ): JSX.Element => {
    switch (column) {
      case 'monthBudget':
        return (
          <EditableNumberCell
            initialValue={Number(row.monthBudget) ?? 0}
            onSaveValue={(value: number) =>
              handleUpdateBudgetMonthSum(row.id, createBudgetMonth(value))
            }
            renderValue={renderBudgetSumItem}
          />
        );
      case 'target':
        return <>{sumWithCurrency(row[column] ?? 0)}</>;
      default:
        return <>{row[column]}</>;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ marginBottom: '20px' }}>
        <MonthSelector target='budgetMonth' />
      </Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <SortableTable
          columns={budgetColumns}
          rows={budgetRows}
          indexBy="id"
          defaultOrderBy={'name'}
          customCellBuilder={budgetsCustomCellBuilder}
        />
      </Paper>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <SortableTable
          columns={accumulationsColumns}
          rows={accumulationRows}
          indexBy="id"
          defaultOrderBy={'name'}
          customCellBuilder={accumulationsCustomCellsBuilder}
        />
      </Paper>
      <Box>
        <BudgetMonthSummary
          totalBudget={totalMonthBudget}
          totalBudgetSpent={totalMonthBudgetSpent}
          totalAccumulationSpent={totalMonthAccumulationSpent}
          currentBudgetBalance={currentBudgetBalance}
        />
      </Box>
    </Box>
  );
};

export default CurrentBudgetMonth;
