import { Box } from '@mui/system';
import { routes } from 'common/constants';
import BackToButton from 'components/Shared/BackToButton';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import { getUserData } from 'core/store/actions/userData.actions';
import { categoriesSelector } from 'core/store/selectors/categories.selector';
import { periodSelector } from 'core/store/selectors/period.selector';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Category from './Category';

const CategoryPage: FC = () => {
  const month = useAppSelector(periodSelector('category'));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { categoryId } = useParams<{ categoryId: string }>();
  const { categories } = useAppSelector(categoriesSelector);
  const category = useMemo(
    () => categories.find((b) => b.id === categoryId),
    [categoryId, categories]
  );

  useEffect(() => {
    !categories?.length && dispatch(getUserData());
  }, []);

  useEffect(() => {
    categoryId && categories?.length && !category && history.push(routes.home);
  }, [categoryId, categories, category]);

  return (
    <Box sx={{ pt: 1 }}>
      <BackToButton />
      <Box>
        {category && (
          <Category
            month={month}
            category={category}
          />
        )}
      </Box>
    </Box>
  );
};

export default CategoryPage;
