import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import SavingsIcon from '@mui/icons-material/Savings';
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  BottomNavigationActionProps, Paper,
  SxProps
} from '@mui/material';
import { routes } from 'common/constants';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

const getPathRoot = (path: string) => {
  const slashIndex = path.indexOf('/', 1);
  return slashIndex === -1 ? path : path.substring(0, slashIndex);
} 

const BottomNavigation: FC = () => {
  const history = useHistory();

  const sx: SxProps = {
    display: {
      sm: 'none',
      xs: 'flex',
    },
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  };

  const BottomNavigationActionWithComponent = <C extends React.ElementType>(
    props: BottomNavigationActionProps<C, { component?: C }>
  ) => {
    return <BottomNavigationAction {...props} />;
  };

  return (
    <Paper sx={sx} elevation={3}>
      <MuiBottomNavigation
        showLabels
        value={getPathRoot(history.location.pathname)}
        sx={sx}
      >
        <BottomNavigationActionWithComponent
          component={Link}
          to={routes.home}
          value={routes.home}
          label="Expenses"
          icon={<AccountBalanceWalletIcon />}
        />
        <BottomNavigationActionWithComponent
          label="Budgets"
          icon={<SavingsIcon />}
          component={Link}
          to={routes.budgets}
          value={routes.budgets}
        />
        <BottomNavigationActionWithComponent
          label="Account"
          icon={<PersonIcon />}
          component={Link}
          to={routes.settings}
          value={routes.settings}
        />
      </MuiBottomNavigation>
    </Paper>
  );
};

export default BottomNavigation;
