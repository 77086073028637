import { Box, Button, Paper, Typography } from '@mui/material';
import { routes } from 'common/constants';
import { useAppSelector } from 'core/hooks/hooks';
import { sendConfirmEmail } from 'core/store/actions/auth.actions';
import { authSelector } from 'core/store/selectors/auth.selector';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const ConfirmEmail: FC = () => {
  const history = useHistory();
  const { shouldConfirmEmail } = useAppSelector(authSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldConfirmEmail) {
      history.push(routes.home);
    }
  }, [shouldConfirmEmail]);

  const handleResendConfirmation = () => {
    if (shouldConfirmEmail) {
      dispatch(sendConfirmEmail({ email: shouldConfirmEmail }));
    }
    history.push(routes.login);
  };

  return (
    <Paper sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Confirm your email
      </Typography>
      <Typography>
        A confirmation email has been sent to {shouldConfirmEmail}
      </Typography>
      <Typography>
        Please click the button in the mail to verify your email address and
        confirm that you are the owner of this account.
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="outlined"
          onClick={handleResendConfirmation}
          sx={{ mr: 1 }}
        >
          Resend confirmation email
        </Button>
        <Button variant="outlined" onClick={() => history.push(routes.login)}>
          To login page
        </Button>
      </Box>
    </Paper>
  );
};

export default ConfirmEmail;
