import { Route, useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from './core/hooks/hooks';
import { isUserAuthenticated } from './core/services/user.service';
import { resetUser } from './core/store/reducers/user.reducer';
import { authSelector } from 'core/store/selectors/auth.selector';
import { routes } from 'common/constants';
import { useAuth } from 'core/hooks/useAuth';

export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  path
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAuth = isUserAuthenticated();
  const { shouldConfirmEmail } = useAppSelector(authSelector);

  useAuth();

  if (!isAuth) {
    localStorage.removeItem('token');
    dispatch(resetUser());
    history.push('/login');
  }

  if (isAuth && shouldConfirmEmail) {
    history.push(routes.confirmEmail);
  }

  return isAuth ? (
    <AppRoute path={path} component={Component} layout={Layout} />
  ) : (
    <></>
  );
};
