import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface IRightDrawer {
  title: string;
  isOpen: boolean;
  closePanel: () => void;
  children: React.ReactNode;
}

const sx: {
  root: SxProps;
  container: SxProps;
  title: SxProps;
  closeButton: SxProps;
} = {
  root: {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 2,
    position: 'relative',
    '& .MuiPaper-root': {
      borderRadius: '1rem 0 0 1rem',
      width: {
        xs: '100%',
        sm: '600px',
      },
    },
    
  },
  container: {
    padding: '30px',
    width: {
      xs: '100%',
      md: '600px',
    },
  },
  title: {
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
};

const RightDrawer: FC<IRightDrawer> = ({
  isOpen,
  closePanel,
  title,
  children,
}) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={closePanel} sx={sx.root}>
      <Box sx={sx.container}>
        <Typography variant="h5" sx={sx.title}>
          {title}
        </Typography>
        {children}
      </Box>
      <IconButton sx={sx.closeButton} size="large" onClick={closePanel}>
        <CloseIcon />
      </IconButton>
    </Drawer>
  );
};

export default RightDrawer;
