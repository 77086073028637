import { Box, Paper, SxProps } from '@mui/material';
import { SidebarLogo } from 'components/BaseLayout/BaseLayout';
import { FC } from 'react';

const sx: SxProps = {
  display: {
    xs: 'block',
    md: 'none',
  },
  ml: -2,
  p: 2,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1,
};

const MobileHeader: FC = ({ children }) => {
  return (
    <Paper sx={sx}>
      <SidebarLogo />
      {children && <Box sx={{ mt: 1 }}>{children}</Box>}
    </Paper>
  );
};

export default MobileHeader;
