import axios, { AxiosRequestConfig } from 'axios';

const axiosClient = axios.create();

axiosClient.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  requestConfig.headers = {
    ...requestConfig.headers,
    Authorization: `Bearer ${token}`
  };
  return requestConfig;
});

export default axiosClient;
