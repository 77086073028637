import { Box, Paper, SxProps, Typography } from '@mui/material';
import { emptyCells } from 'common/constants';
import { IBudget, ICategory, IExpense } from 'common/entities';
import { ITableColumn } from 'common/types';
import SortableTable, { TableSxProps } from 'components/Shared/SortableTable';
import { useAppSelector } from 'core/hooks/hooks';
import { sumWithCurrency } from 'core/services/currency.service';
import {
  getBudgetMonthSpent,
  getCategorySpent,
  getExpenseMonthTotal,
} from 'core/services/expense.service';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { categoriesSelector } from 'core/store/selectors/categories.selector';
import { expensesSelector } from 'core/store/selectors/expenses.selector';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

interface IMonthExpenses {
  month: Date;
}

interface IMonthExpenseRow {
  id: string;
  name: string;
  amount: number;
}

const categoryColumns: ITableColumn<IMonthExpenseRow>[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Category',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Amount',
    align: 'right',
  },
];

const budgetColumns: ITableColumn<IMonthExpenseRow>[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Budget',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Amount',
    align: 'right',
  },
];

const createCategoryRow = (
  category: ICategory | undefined,
  expenses: IExpense[],
  month: Date
): IMonthExpenseRow => ({
  id: category?.id ?? '0',
  name: category?.name ?? emptyCells.budget,
  amount: getCategorySpent(category?.id, expenses, month),
});

const createBudgetRow = (
  budget: IBudget | undefined,
  expenses: IExpense[],
  month: Date
): IMonthExpenseRow => ({
  id: budget?.id ?? '0',
  name: budget?.name ?? emptyCells.budget,
  amount: getBudgetMonthSpent(budget?.id, expenses, month),
});

const styles: TableSxProps = {
  headCell: {
    p: '10px 0',
  },
  cell: {
    p: '5px 0',
  },
};

const customCellBuilder = (
  column: keyof IMonthExpenseRow,
  row: IMonthExpenseRow
): JSX.Element => {
  switch (column) {
    case 'amount':
      return <>{sumWithCurrency(row['amount'])}</>;
    default:
      return <>{row[column]}</>;
  }
};

const categoriesCustomCellBuilder = (
  column: keyof IMonthExpenseRow,
  row: IMonthExpenseRow
): JSX.Element => {
  switch (column) {
    case 'name':
      return row.id === '0' ? (
        <>{row.name}</>
      ) : (
        <Link
          to={{
            pathname: `/category/${row.id}`,
            state: { goBackTitle: 'Back to expenses' },
          }}
          style={{ color: theme.palette.common.black }}
        >{row.name}</Link>
      );
    default:
      return customCellBuilder(column, row);
  }
};

const budgetsCustomCellBuilder = (
  column: keyof IMonthExpenseRow,
  row: IMonthExpenseRow
): JSX.Element => {
  switch (column) {
    case 'name':
      return row.id === '0' ? (
        <>{row.name}</>
      ) : (
        <Link
          to={{
            pathname: `/budget/${row.id}`,
            state: { goBackTitle: 'Back to expenses' },
          }}
          style={{ color: theme.palette.common.black }}
        >{row.name}</Link>
      );
    default:
      return customCellBuilder(column, row);
  }
};

const MonthExpenses: FC<IMonthExpenses> = ({ month }) => {
  const { expenses } = useAppSelector(expensesSelector('expenses'));
  const { categories } = useAppSelector(categoriesSelector);
  const { budgets } = useAppSelector(budgetsSelector);

  const categoryRows = useMemo(
    () =>
      [createCategoryRow(undefined, expenses, month)].concat(
        categories.map((c) => createCategoryRow(c, expenses, month))
      ),
    [expenses, month, categories]
  );

  const budgetRows = useMemo(
    () =>
      [createBudgetRow(undefined, expenses, month)].concat(
        budgets.map((b) => createBudgetRow(b, expenses, month))
      ),
    [expenses, month, budgets]
  );

  const expensesMonthTotal = useMemo(
    () => getExpenseMonthTotal(expenses, month),
    [expenses, month]
  );

  return (
    <Paper sx={{ px: 3, py: '20px' }}>
      <Typography variant="h5" sx={{ mb: '10px' }}>
        Month Expenses
      </Typography>
      <Box sx={{ mb: '20px' }}>
        <Typography variant="subtitle1">By categories:</Typography>
        <SortableTable
          columns={categoryColumns}
          rows={categoryRows}
          indexBy="id"
          styles={styles}
          customCellBuilder={categoriesCustomCellBuilder}
        />
      </Box>
      <Box>
        <Typography variant="subtitle1">By budgets:</Typography>
        <SortableTable
          columns={budgetColumns}
          rows={budgetRows}
          indexBy="id"
          styles={styles}
          customCellBuilder={budgetsCustomCellBuilder}
        />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}
      >
        <Typography variant="subtitle1">Month Total:</Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {sumWithCurrency(expensesMonthTotal)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default MonthExpenses;
