import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISetBudgetMonthPayload } from 'common/types';
import { IBudget, IBudgetBase } from '../../../common/entities';
import axios from '../../api/axiosClient';
import { endpoints } from '../../api/endpoints';

export const listBudgets = createAsyncThunk(
  'budget/list',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IBudget[]>(endpoints.budget);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getBudget = createAsyncThunk(
  'budget/get',
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<IBudget>(`${endpoints.budget}/${id}`);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const addBudget = createAsyncThunk(
  'budget/add',
  async (data: IBudgetBase, thunkAPI) => {
    try {
      const response = await axios.post(endpoints.budget, data);
      thunkAPI.dispatch(listBudgets());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const updateBudget = createAsyncThunk(
  'budget/update',
  async (data: { id: string; budget: IBudgetBase }, thunkAPI) => {
    const { id, budget } = data;
    try {
      const response = await axios.put(`${endpoints.budget}/${id}`, budget);
      thunkAPI.dispatch(listBudgets());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const deleteBudget = createAsyncThunk(
  'budget/delete',
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${endpoints.budget}/${id}`);
      thunkAPI.dispatch(listBudgets());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const setBudgetMonth = createAsyncThunk(
  'budget/month/set',
  async (payload: ISetBudgetMonthPayload, thunkAPI) => {
    try {
      const response = await axios.post(`${endpoints.budgetMonth}`, payload);
      thunkAPI.dispatch(listBudgets());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);
