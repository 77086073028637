import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  Typography
} from '@mui/material';
import { routes } from 'common/constants';
import NotificationPanel from 'components/Shared/NotificationPanel';
import {
  NotificationContextProvider,
  useNotificationContext
} from 'core/providers/NotificationContextProvider';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IUserCredentials } from '../../common/entities';
import { IFormState, IValidationState } from '../../common/types';
import { useAppDispatch, useAppSelector } from '../../core/hooks/hooks';
import { useForm } from '../../core/hooks/useForm';
import { isUserAuthenticated } from '../../core/services/user.service';
import {
  confirmEmail,
  login,
  sendConfirmEmail
} from '../../core/store/actions/auth.actions';
import { authSelector } from '../../core/store/selectors/auth.selector';
import { FormTextField } from '../controls/formControls';

function createUserCredentials(formState: IFormState): IUserCredentials {
  return {
    email: formState.email.value,
    password: formState.password.value
  };
}

const initialFormState: IFormState = {
  email: { value: '' },
  password: { value: '' }
};

const validationState: IValidationState = {
  email: { required: true, customRequiredMessage: 'Enter email' },
  password: { required: true, customRequiredMessage: 'Enter password' }
};

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { showNotification } = useNotificationContext();
  const { error, user, shouldConfirmEmail, lastAction } =
    useAppSelector(authSelector);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    switch (lastAction) {
      case confirmEmail.fulfilled.type:
        showNotification({
          message: `Email has been confirmed. You can now sign in.`
        });
        break;
      case confirmEmail.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
      case sendConfirmEmail.fulfilled.type:
        showNotification({
          message: `Confirmation email has been sent to ${shouldConfirmEmail}. Confirm your email to sign in.`
        });
        break;
      case sendConfirmEmail.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  const loginCallback = event => {
    event.preventDefault();
    setIsSubmitted(true);
    dispatch(login(createUserCredentials(formState)));
  };

  const { formState, updateFormState, handleSubmit } = useForm(
    initialFormState,
    validationState,
    loginCallback
  );

  useEffect(() => {
    if (user && isUserAuthenticated()) {
      shouldConfirmEmail
        ? history.push(routes.confirmEmail)
        : !shouldConfirmEmail && history.push(routes.home);
    }
  }, [user]);

  const handleInputChange = event =>
    updateFormState(event.target.name, event.target.value);

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {isSubmitted && error && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error}
        </Alert>
      )}
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <FormTextField
          type="text"
          id="email"
          name="email"
          title="Email"
          handleChange={handleInputChange}
          error={formState.email.error}
          textFieldProps={{
            autoComplete: 'email',
            autoFocus: true,
            required: true
          }}
        />
        <FormTextField
          type="password"
          id="password"
          name="password"
          title="Password"
          handleChange={handleInputChange}
          error={formState.password.error}
          textFieldProps={{ autoComplete: 'current-password', required: true }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item>
            <Link
              component="button"
              onClick={() => history.push('/registration')}
            >
              Registration
            </Link>
          </Grid>
        </Grid>
      </Box>
      <NotificationPanel />
    </>
  );
};

const LoginPageWithProvider = () => (
  <NotificationContextProvider>
    <LoginPage />
  </NotificationContextProvider>
);

export default LoginPageWithProvider;
