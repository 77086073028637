import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      light: '#68b36b',
      main: '#346439',
      dark: '#2e7031'
    },
    secondary: {
      light: '#b1b04f',
      main: '#888',
      dark: '#6e6d19'
    },
    background: {
      default: '#fbfbf5'
    },
    common: {
      black: '#000'
    }
  },
  typography: {
    fontFamily: 'Inter, Roboto, sans-serif',
    fontSize: 11,
    h1: {
      fontSize: '20px',
      fontWeight: 500
    },
    h2: {
      fontSize: '18px',
      fontWeight: 500
    },
    h3: {
      fontSize: '16px',
      fontWeight: 400
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '1rem',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          borderRadius: '1rem'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '8px 0'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          ':last-child': {
            paddingBottom: '8px'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':last-of-type': {
            borderRadius: 0
          },
          ':first-of-type': {
            borderRadius: 0
          },
          boxShadow: 'none',
          borderRadius: 0,
          borderBottom: '1px solid #ccc',
          '.MuiAccordionSummary-root': {
            padding: 0
          },
          '.MuiAccordionSummary-content': {
            marginBottom: '0'
          },
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '20px 0 10px'
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            marginTop: '10px'
          },
          '.MuiAccordionDetails-root': {
            padding: '0 0 16px'
          }
        }
      }
    }
  }
});
