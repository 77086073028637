import { resetBudgets } from 'core/store/reducers/budgets.reducer';
import { resetCategories } from 'core/store/reducers/categories.reducer';
import { resetExpenses } from 'core/store/reducers/expenses.reducer';
import { resetPeriods } from 'core/store/reducers/period.reducer';
import { resetUser } from 'core/store/reducers/user.reducer';

export const logout = (history, dispatch) => {
  localStorage.removeItem('token');
  dispatch(resetBudgets());
  dispatch(resetCategories());
  dispatch(resetExpenses());
  dispatch(resetPeriods());
  dispatch(resetUser());
  history.push('/login');
};
