import {
  Box,
  Button, Skeleton
} from '@mui/material';
import { NotificationContext, NotificationContextType } from 'core/providers/NotificationContextProvider';
import { resetCurrentBudget } from 'core/store/reducers/budgets.reducer';
import { FC, useContext, useEffect } from 'react';
import { IBudget, IBudgetBase } from '../../../common/entities';
import { IFormState, IValidationState } from '../../../common/types';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/hooks';
import { useEntityEditForm } from '../../../core/hooks/useEntityEditForm';
import {
  addBudget,
  getBudget,
  updateBudget
} from '../../../core/store/actions/budgets.actions';
import { budgetsSelector } from '../../../core/store/selectors/budgets.selector';
import RightDrawer from '../../BaseLayout/RightDrawer';
import { LabeledCheckbox, Selector, TextField } from '../../controls/formControls';

export interface IEditBudgetForm {
  budgetId?: string;
  isOpen: boolean;
  closePanel: () => void;
}

const initialState: IFormState = {
  name: { value: '' },
  type: { value: 'Budget' },
  default: { value: false },
  defaultBudget: { value: undefined },
  accumulationGoal: { value: undefined },
};

const validationSchema: IValidationState = {
  name: {
    required: true
  },
  defaultBudget: {
    required: false,
    customValidator: (formState: IFormState) => {
      if (formState?.defaultBudget?.value) {
        const numValue = Number(formState.defaultBudget?.value);
        return !isNaN(numValue) && numValue >= 0 && numValue < 1000000;
      }
      return true;
    },
    customValidatorMessage: 'Value should be a positive number.'
  },
  accumulationGoal: {
    required: false,
    customValidator: (formState: IFormState) => {
      if (formState?.accumulationGoal?.value) {
        const numValue = Number(formState.accumulationGoal?.value);
        return !isNaN(numValue) && numValue >= 0 && numValue < 1000000;
      }
      return true;
    },
    customValidatorMessage: 'Value should be a positive number.'
  }
}

const EditBudgetForm: FC<IEditBudgetForm> = ({ budgetId, isOpen, closePanel }) => {
  const { showNotification } = useContext(NotificationContext) as NotificationContextType;
  const { lastAction, error, isLoading, currentBudget } =
    useAppSelector(budgetsSelector);
  const dispatch = useAppDispatch();
  const {
    formState,
    updateEntityProperty,
    handleSubmit,
  } = useEntityEditForm<IBudget>(
    budgetId,
    initialState,
    currentBudget,
    isOpen,
    createFormState,
    addBudgetAction,
    updateBudgetAction,
    getBudget,
    validationSchema
  );
  const accumulation = formState.type?.value === 'Accumulation';

  useEffect(() => {
    switch (lastAction) {
      case addBudget.fulfilled.type:
        closePanel();
        showNotification({
          message: `Budget "${formState.name.value}" has been added`,
        });
        break;
      case addBudget.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
      case getBudget.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        closePanel();
        break;
      case updateBudget.fulfilled.type:
        closePanel();
        showNotification({
          message: `Budget "${formState.name.value}" has been updated`,
        });
        break;
      case updateBudget.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(resetCurrentBudget());
    }
  }, [isOpen]);

  const handleInputChange = (event) => {
    const eventValue =
      event.target.type === 'number' && !isNaN(event.target.valueAsNumber)
        ? event.target.valueAsNumber
        : event.target.value;
    updateEntityProperty(event.target.name, eventValue);
  };

  const handleCheckboxChange = (event) => {
    updateEntityProperty(event.target.name, event.target.checked);
  };

  function createFormState(budget: IBudget): IFormState {
    return {
      name: { value: budget.name },
      type: { value: !budget.accumulation ? 'Budget' : 'Accumulation' },
      default: { value: budget.default },
      defaultBudget: { value: budget.defaultBudget },
      accumulationGoal: { value: budget.accumulationGoal },
    };
  }

  function createBudget(): IBudgetBase {
    return {
      name: formState.name.value,
      accumulation: formState.type.value === 'Accumulation',
      default: formState.default.value,
      defaultBudget: formState.defaultBudget.value,
      accumulationGoal: formState.accumulationGoal.value,
    };
  }

  function addBudgetAction() {
    return addBudget(createBudget());
  }

  function updateBudgetAction() {
    if (!budgetId) return;
    return updateBudget({
      id: budgetId,
      budget: createBudget(),
    });
  }

  const styles = {
    width: '100%',
  };

  return (
    <RightDrawer
      isOpen={isOpen}
      closePanel={closePanel}
      title={budgetId ? 'Edit budget' : 'Add budget'}
    >
      {!isLoading && (
        <Box component="form" sx={styles} onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            value={formState.name.value}
            handleChange={handleInputChange}
            title="Name"
            error={formState.name.error}
          />
          <Selector
            id="type"
            name="type"
            label="Type"
            handleChange={handleInputChange}
            value={formState.type.value}
            options={['Budget', 'Accumulation']}
            disabled={!!currentBudget}
          />
          <TextField
            id="defaultBudget"
            type="number"
            name="defaultBudget"
            value={formState.defaultBudget.value}
            title="Default budget"
            handleChange={handleInputChange}
            error={formState.defaultBudget.error}
          />
          {!accumulation && (
            <LabeledCheckbox
              id="default"
              name="default"
              label="Is Default"
              handleChange={handleCheckboxChange}
              isChecked={!!formState.default?.value}
            />
          )}
          {accumulation && (
            <TextField
              id="accumulationGoal"
              type="number"
              name="accumulationGoal"
              value={formState.accumulationGoal.value}
              title="Accumulation goal"
              handleChange={handleInputChange}
              error={formState.accumulationGoal.error}
            />
          )}
          <Box
            sx={{ display: 'flex', justifyContent: 'right', marginTop: '30px' }}
          >
            <Button type="submit">
              {budgetId ? 'Update budget' : 'Add budget'}
            </Button>
          </Box>
        </Box>
      )}
      {isLoading && (
        <Skeleton variant="rectangular" height="80vh" width="600px" />
      )}
    </RightDrawer>
  );
}

export default EditBudgetForm;
