const apiUrl = process.env.REACT_APP_API_URL;

export const endpoints = {
  userData: `${apiUrl}/userdata`,
  category: `${apiUrl}/category`,
  expense: `${apiUrl}/expense`,
  budget: `${apiUrl}/budget`,
  budgetMonth: `${apiUrl}/budget/month`,
  preferences: `${apiUrl}/preferences`,
  auth: {
    login: `${apiUrl}/auth`,
    register: `${apiUrl}/auth/register`,
    sendConfirmEmail: `${apiUrl}/auth/sendConfirmEmail`,
    confirmEmail: `${apiUrl}/auth/confirmEmail`,
  }
};
