import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import categoryReducer from './reducers/categories.reducer';
import expensesReducer from './reducers/expenses.reducer';
import budgetsReducer from './reducers/budgets.reducer';
import userReducer from './reducers/user.reducer';
import periodReducer from './reducers/period.reducer';

const rootReducer = combineReducers({
  categoryReducer,
  expensesReducer,
  budgetsReducer,
  userReducer,
  periodReducer
})

export const setupStore = () => {
  return configureStore({
      reducer: rootReducer
  })
}

export type AppState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
