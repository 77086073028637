import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserPreferences } from 'common/entities';
import axios from '../../api/axiosClient';
import { endpoints } from '../../api/endpoints';

export const getPreferences = createAsyncThunk(
  'preferences/get',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<IUserPreferences>(endpoints.preferences);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updatePreferences = createAsyncThunk(
  'preferences/update',
  async (data: Partial<IUserPreferences>, thunkAPI) => {
    try {
      const response = await axios.put(endpoints.preferences, data);
      thunkAPI.dispatch(getPreferences());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);
