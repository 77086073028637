import { Box, Button, Paper, Skeleton, Typography } from '@mui/material';
import { IUserPreferences } from 'common/entities';
import NotificationPanel from 'components/Shared/NotificationPanel';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import {
  useNotificationContext
} from 'core/providers/NotificationContextProvider';
import { listBudgets } from 'core/store/actions/budgets.actions';
import {
  getPreferences,
  updatePreferences
} from 'core/store/actions/preferences.actions';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { preferencesSelector } from 'core/store/selectors/preferences.selector';
import { FC, useCallback, useEffect, useState } from 'react';
import { CollapseSettingItem } from '../common/CollapseSettingItem';
import { ExpenseDayBudgetSummary } from './ExpenseDayBudgetSummary';

interface IExpandedState {
  expenseDayBudgets: boolean;
}

const initialPreferencesState: IUserPreferences = {
  expenseDayBudgetsSummary: []
};

const initialExpandedState: IExpandedState = {
  expenseDayBudgets: false
};

const Preferences: FC = () => {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationContext();
  const { hasLoaded: hasBudgetsLoaded } = useAppSelector(budgetsSelector);
  const {
    lastAction,
    error,
    hasLoaded: hasPreferencesLoaded,
    preferences: userPreferences,
    isLoading
  } = useAppSelector(preferencesSelector);

  const [expanded, setExpanded] =
    useState<IExpandedState>(initialExpandedState);

  const [preferencesState, setPreferencesState] = useState<IUserPreferences>(
    initialPreferencesState
  );
  const [hasUnsaved, setHasUnsaved] = useState<boolean>(false);

  useEffect(() => {
    !hasBudgetsLoaded && dispatch(listBudgets());
    !hasPreferencesLoaded && dispatch(getPreferences());
  }, []);

  useEffect(() => {
    setPreferencesState(userPreferences);
  }, [userPreferences]);

  useEffect(() => {
    switch (lastAction) {
      case updatePreferences.rejected.type:
        showNotification({
          isError: true,
          message: `Failed to update preferences: ${error}`
        });
        break;
      case updatePreferences.fulfilled.type:
        setHasUnsaved(false);
        showNotification({
          message: `Preferences has been updated`
        });
        break;
    }
  }, [lastAction]);

  const toggleAccordion = (item: keyof IExpandedState) =>
    setExpanded(state => ({
      ...state,
      [item]: !state[item]
    }));

  const updateSelectedBudgets = useCallback(
    (budgets: string[]) => {
      setPreferencesState({
        ...preferencesState,
        expenseDayBudgetsSummary: budgets
      });
      setHasUnsaved(true);
    },
    [setPreferencesState, preferencesState, setHasUnsaved]
  );

  const handleUpdatePreferences = useCallback(() => {
    dispatch(updatePreferences(preferencesState));
  }, [preferencesState]);

  return (
    <Box>
      <Typography variant="h2" sx={{ pt: 1, pb: 2 }}>
        Preferences
      </Typography>
      <Box>
        <Paper sx={{ p: 2, minHeight: '60vh' }}>
          {!isLoading && (
            <Box>
              <Typography variant="h3">Expense day</Typography>
              <CollapseSettingItem
                expanded={expanded.expenseDayBudgets}
                onChange={() => toggleAccordion('expenseDayBudgets')}
                title="Expense day budget total:"
              >
                <ExpenseDayBudgetSummary
                  selectedBudgetIds={preferencesState.expenseDayBudgetsSummary}
                  updateSelectedBudgets={updateSelectedBudgets}
                />
              </CollapseSettingItem>
              {hasUnsaved && (
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleUpdatePreferences()}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          )}
          {isLoading && <Skeleton variant="rectangular" height="30vh" />}
        </Paper>
      </Box>
      <NotificationPanel />
    </Box>
  );
};

export default Preferences;
