import { Button } from '@mui/material';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const BackToButton: FC = () => {
  const history = useHistory();
  return (history.location.state as any)?.goBackTitle ? (
    <Button
      variant="outlined"
      startIcon={<ChevronLeftIcon />}
      size="small"
      sx={{ mb: 2, borderRadius: '13px' }}
      color="secondary"
      onClick={() => history.goBack()}
    >
      {(history.location.state as any)?.goBackTitle}
    </Button>
  ) : (
    <></>
  );
};

export default BackToButton;
