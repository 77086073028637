import { Box, Button } from '@mui/material';
import { dateVars } from 'common/constants';
import { MonthPicker } from 'components/controls/formControls';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import { listExpenses } from 'core/store/actions/expenses.actions';
import { IPeriodState, setPeriod } from 'core/store/reducers/period.reducer';
import { periodSelector } from 'core/store/selectors/period.selector';
import { Moment } from 'moment';
import { FC, useCallback, useMemo, useState } from 'react';
import { compareMonth, getMonthItem, isCurrentMonth } from 'utils/dateUtils';

export interface IMonthSelector {
  target: keyof IPeriodState;
  fullWidth?: boolean;
}

const today = new Date();

export const MonthSelector: FC<IMonthSelector> = ({ target, fullWidth }) => {
  const selectedMonth = useAppSelector(periodSelector(target));
  const [updatedMonth, setUpdatedMonth] = useState<Date>(selectedMonth);
  
  const dispatch = useAppDispatch();

  const budgetMonthHasChanged = useMemo(
    () => !compareMonth(selectedMonth, updatedMonth),
    [selectedMonth, updatedMonth]
  );

  const handleUpdateBudgetMonth = useCallback((value: Moment) => {
    if (value && value.isValid()) {
      const dateValue = value.toDate();
      dateValue >= dateVars.min &&
        dateValue <= dateVars.max &&
        setUpdatedMonth(dateValue);
    }
  }, []);

  const updateMonth = (month: Date) => {
    const monthItem = getMonthItem(month);
    dispatch(setPeriod({ target, month: monthItem }));
    dispatch(listExpenses(monthItem));
  };

  const handleApplyBudgetMonth = useCallback(
    () => updateMonth(updatedMonth),
    [updatedMonth]
  );

  const handleResetBudgetMonth = useCallback(() => {
    updateMonth(today);
    setUpdatedMonth(today);
  }, []);

  const currentMonth = useMemo(() => isCurrentMonth(selectedMonth), [selectedMonth]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flexGrow: fullWidth ? 1 : undefined }}>
        <MonthPicker
          value={updatedMonth}
          onChange={(value: Moment) => handleUpdateBudgetMonth(value)}
          fullWidth={fullWidth}
        />
      </Box>
      {budgetMonthHasChanged && (
        <Button
          variant="outlined"
          size="small"
          sx={{ marginLeft: '10px' }}
          onClick={handleApplyBudgetMonth}
        >
          apply
        </Button>
      )}
      {!currentMonth && (
        <Button
          variant="outlined"
          size="small"
          sx={{ marginLeft: '10px' }}
          onClick={handleResetBudgetMonth}
        >
          reset
        </Button>
      )}
    </Box>
  );
};
