import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMonth } from 'common/types';
import { IExpense } from '../../../common/entities';
import {
  addExpense,
  deleteExpense,
  getExpense,
  listExpenses,
  updateExpense
} from '../actions/expenses.actions';

export interface IExpensesState {
  expenses: IExpense[];
  isLoading: boolean;
  error?: string;
  lastAction: string;
  currentExpense?: IExpense;
  hasLoaded?: boolean;
  currentFilter?: IMonth;
}

const initialState: IExpensesState = {
  hasLoaded: false,
  expenses: [],
  isLoading: false,
  lastAction: '',
  currentExpense: undefined
};

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    resetExpenses(state, action: PayloadAction) {
      state.expenses = [];
      state.hasLoaded = false;
      state.isLoading = false;
      state.lastAction = '';
      state.currentExpense = undefined;
    }
  },
  extraReducers: {
    [listExpenses.fulfilled.type]: (
      state,
      action: PayloadAction<IExpense[], any, { arg: IMonth }>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.expenses = action.payload;
      state.currentFilter = action.meta.arg;
      state.lastAction = action.type;
      state.hasLoaded = true;
    },
    [listExpenses.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = state.lastAction = action.type;
    },
    [listExpenses.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
      state.currentFilter = undefined;
    },
    [getExpense.fulfilled.type]: (state, action: PayloadAction<IExpense>) => {
      state.isLoading = false;
      state.error = '';
      state.currentExpense = action.payload;
      state.lastAction = action.type;
    },
    [getExpense.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = state.lastAction = action.type;
    },
    [getExpense.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [addExpense.fulfilled.type]: (state, action: PayloadAction<IExpense>) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [addExpense.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [addExpense.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [updateExpense.fulfilled.type]: (
      state,
      action: PayloadAction<IExpense>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [updateExpense.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [updateExpense.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    },
    [deleteExpense.fulfilled.type]: (
      state,
      action: PayloadAction<IExpense>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.lastAction = action.type;
    },
    [deleteExpense.pending.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.lastAction = action.type;
    },
    [deleteExpense.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.lastAction = action.type;
    }
  }
});

export const { resetExpenses } = expensesSlice.actions;
export default expensesSlice.reducer;
