import { IPeriodState } from '../reducers/period.reducer';
import { AppState } from '../store';

export const expensesSelector =
  (target: keyof IPeriodState) => (state: AppState) => {
    const targetMonth = state.periodReducer[target];
    const currentFilter = state.expensesReducer.currentFilter;
    return {
      ...state.expensesReducer,
      expensesLoaded:
        state.expensesReducer.hasLoaded &&
        currentFilter &&
        targetMonth.year === currentFilter.year &&
        targetMonth.month === currentFilter.month
    };
  };
