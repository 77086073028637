import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'core/api/axiosClient';
import { ICategory } from '../../../common/entities';
import { endpoints } from '../../api/endpoints';

export const listCategories = createAsyncThunk(
  'category/list',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ICategory[]>(endpoints.category);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getCategory = createAsyncThunk(
  'category/get',
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.get<ICategory>(
        `${endpoints.category}/${id}`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const addCategory = createAsyncThunk(
  'category/add',
  async (data: Partial<ICategory>, thunkAPI) => {
    try {
      const response = await axios.post(endpoints.category, data);
      thunkAPI.dispatch(listCategories());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  'category/update',
  async (data: { id: string; category: Partial<ICategory> }, thunkAPI) => {
    const { id, category } = data;
    try {
      const response = await axios.put(`${endpoints.category}/${id}`, category);
      thunkAPI.dispatch(listCategories());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'category/delete',
  async (id: string, thunkAPI) => {
    try {
      const response = await axios.delete(`${endpoints.category}/${id}`);
      thunkAPI.dispatch(listCategories());
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);
