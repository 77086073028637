import { routes } from 'common/constants';
import { useAppDispatch, useAppSelector } from 'core/hooks/hooks';
import { confirmEmail } from 'core/store/actions/auth.actions';
import { authSelector } from 'core/store/selectors/auth.selector';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const ConfirmEmailCallback = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get('token');
  const { lastAction } = useAppSelector(authSelector);

  useEffect(() => {
    if (token) {
      dispatch(confirmEmail({ token }));
    } else {
      history.push(routes.login);
    }
  }, []);

  useEffect(() => {
    if (
      lastAction === confirmEmail.fulfilled.type ||
      lastAction === confirmEmail.rejected.type
    ) {
      history.push(routes.login);
    }
  }, [lastAction]);

  return (
    <div>
      <h1>Validating token...</h1>
    </div>
  );
};
