import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button, SxProps,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { FC } from 'react';

export interface IBudgetsMobileHeader {
  currentTabIndex: number;
  onSwitchTab: (tabIndex: number) => void;
  onAddBudget: () => void;
}

const sx: {
  container: SxProps;
  buttonGroup: SxProps;
  tabButton: SxProps;
  addButton: SxProps;
} = {
  container: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'flex',
    flexGrow: 1,
  },
  tabButton: {
    flexGrow: 1,
  },
  addButton: {
    marginLeft: 1,
  },
};

const BudgetsMobileHeader: FC<IBudgetsMobileHeader> = ({
  currentTabIndex,
  onSwitchTab,
  onAddBudget,
}) => {
  const handleSwitchTab = (_: any, tabIndex: number) => onSwitchTab(tabIndex);

  return (
    <Box sx={sx.container}>
      <ToggleButtonGroup
        size="small"
        exclusive
        sx={sx.buttonGroup}
        value={currentTabIndex}
        onChange={handleSwitchTab}
      >
        <ToggleButton value={0} sx={sx.tabButton}>
          Budgets
        </ToggleButton>
        <ToggleButton value={1} sx={sx.tabButton}>
          Budget month
        </ToggleButton>
      </ToggleButtonGroup>
      <Button variant="outlined" sx={sx.addButton} onClick={onAddBudget}>
        <AddCircleOutlineIcon />
      </Button>
    </Box>
  );
};

export default BudgetsMobileHeader;
