import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import MobileHeader from 'components/mobile/MobileHeader';
import { NotificationContextProvider } from 'core/providers/NotificationContextProvider';
import { periodSelector } from 'core/store/selectors/period.selector';
import { FC, useEffect, useMemo, useState } from 'react';
import { getMonthTitle, isCurrentMonth } from 'utils/dateUtils';
import { useAppDispatch, useAppSelector } from '../../core/hooks/hooks';
import { useEntityContext } from '../../core/hooks/useEntityContext';
import { listBudgets } from '../../core/store/actions/budgets.actions';
import { listExpenses } from '../../core/store/actions/expenses.actions';
import { budgetsSelector } from '../../core/store/selectors/budgets.selector';
import NotificationPanel from '../Shared/NotificationPanel';
import BudgetList from './BudgetList/BudgetList';
import EditBudgetForm from './BudgetList/EditBudgetForm';
import CurrentBudgetMonth from './CurrentBudgetMonth/CurrentBudgetMonth';
import BudgetsMobileHeader from './mobile/BudgetsMobileHeader';

const currentDate = new Date();

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Budgets: FC = () => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [isEditBudgetPanelOpen, setIsEditBudgetPanelOpen] =
    useState<boolean>(false);
  const context = useEntityContext<string>();
  const { budgets } = useAppSelector(budgetsSelector);
  const budgetMonth = useAppSelector(periodSelector('budgetMonth'));
  const currentMonth = useMemo(
    () => isCurrentMonth(budgetMonth),
    [budgetMonth]
  );

  const setTab = (tabValue: number) => setCurrentTab(tabValue);

  useEffect(() => {
    dispatch(listBudgets());
    dispatch(listExpenses());
  }, []);

  const openEditForm = () => {
    setIsEditBudgetPanelOpen(true);
  };

  const closeEditForm = () => {
    context.reset();
    setIsEditBudgetPanelOpen(false);
  };

  return (
    <NotificationContextProvider>
      <MobileHeader>
        <BudgetsMobileHeader
          currentTabIndex={currentTab}
          onSwitchTab={setTab}
          onAddBudget={openEditForm}
        />
      </MobileHeader>
      <Box
        sx={{
          mt: {
            xs: '100px',
            md: 0
          },
          mb: 9
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'relative',
            marginBottom: '1em',
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        >
          <Typography
            variant="h6"
            sx={{ position: 'absolute', bottom: '0.5em' }}
          >
            Budgets
          </Typography>
          <Tabs
            value={currentTab}
            onChange={(_, tabIndex: number) => setTab(tabIndex)}
            sx={{ paddingLeft: '100px' }}
          >
            <Tab label="All" />
            <Tab
              label={
                currentMonth ? 'Current month' : getMonthTitle(budgetMonth)
              }
            />
          </Tabs>
          <Button
            variant="outlined"
            size="small"
            onClick={() => openEditForm()}
            sx={{ position: 'absolute', bottom: '0.5em', right: '0' }}
          >
            Add budget
          </Button>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <BudgetList
            context={context}
            openEditForm={openEditForm}
            budgets={budgets}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <CurrentBudgetMonth budgets={budgets} budgetMonth={budgetMonth} />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          Item Three
        </TabPanel>
        <EditBudgetForm
          isOpen={isEditBudgetPanelOpen}
          closePanel={closeEditForm}
          budgetId={context.current}
        />
        <NotificationPanel />
      </Box>
    </NotificationContextProvider>
  );
};

export default Budgets;
