import { MoreHoriz } from '@mui/icons-material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { emptyCells } from 'common/constants';
import { useAppSelector } from 'core/hooks/hooks';
import { sumWithCurrency } from 'core/services/currency.service';
import {
  getBudgetSpent,
  getExpenseDayTotal,
} from 'core/services/expense.service';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { categoriesSelector } from 'core/store/selectors/categories.selector';
import { preferencesSelector } from 'core/store/selectors/preferences.selector';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDayTitle, isToday } from 'utils/dateUtils';
import { IExpense } from '../../../common/entities';

export interface IExpenseDay {
  date: Date;
  expenses: IExpense[];
  openEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  onMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    expenseId: string
  ) => void;
  onCreateExpense: (expenseDate: Date) => void;
}

interface IExpenseDayEntry {
  expense: IExpense;
  onMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    expenseId: string
  ) => void;
  onCreateExpense: (expenseDate: Date) => void;
}

const expenseDayStyles = {
  root: {
    width: '100%',
  },
};

const expenseEntryStyles = {
  root: {
    p: 0,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  c_left: {
    justifyContent: 'flex-start',
  },
  c_right: {
    justifyContent: 'flex-end',
  },
};

const ExpenseDayEntry: FC<IExpenseDayEntry> = ({ expense, onMenuClick }) => {
  const { categories } = useSelector(categoriesSelector);
  const renderOptionalCell = (item: any, noItemText: string) => {
    return item ? (
      <Typography>{item}</Typography>
    ) : (
      <Typography sx={{ color: '#999' }}>{noItemText}</Typography>
    );
  };
  return (
    <Grid container alignItems="center" sx={expenseEntryStyles.root}>
      <Grid container item alignItems="center" sx={expenseEntryStyles.c_left}>
        <Grid item sx={{ width: '100px' }}>
          {renderOptionalCell(
            expense.categoryId &&
              categories.find((c) => c.id === expense.categoryId)?.name,
            '<no category>'
          )}
        </Grid>
        <Grid item>
          {renderOptionalCell(expense.description, emptyCells.description)}
        </Grid>
      </Grid>
      <Grid container item alignItems="center" sx={expenseEntryStyles.c_right}>
        <Grid item>
          <Typography>{sumWithCurrency(expense.amount)}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            sx={{ p: '5px', ml: '10px' }}
            onClick={(e) => onMenuClick(e, expense.id)}
          >
            <MoreHoriz />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ExpenseDaySummaryItem: FC<{ title: string; value: number }> = ({
  title,
  value,
}) => (
  <Typography sx={{ textAlign: 'right', mb: 1, mr: '40px' }}>
    {title}:
    <Typography component="span" sx={{ fontWeight: 500, ml: '10px' }}>
      {sumWithCurrency(value)}
    </Typography>
  </Typography>
);

const ExpenseDay: FC<IExpenseDay> = ({
  date,
  expenses,
  openEditForm,
  onMenuClick,
  onCreateExpense,
}) => {
  const today = useMemo(() => isToday(date), [date]);
  const dayTitle = useMemo(() => getDayTitle(date), [date]);
  const cardTitle = today ? 'Today' : dayTitle;

  const { preferences } = useAppSelector(preferencesSelector);
  const { budgets } = useAppSelector(budgetsSelector);

  const expensesDayTotal = useMemo(
    () => getExpenseDayTotal(expenses, date),
    [expenses]
  );

  const expensesList = useMemo(
    () =>
      expenses
        .sort((a, b) => (a.createdAt.getTime() - b.createdAt.getTime()) * -1)
        .map((e) => (
          <ListItem key={e.id} sx={{ py: 0 }}>
            <ExpenseDayEntry
              expense={e}
              onMenuClick={onMenuClick}
              onCreateExpense={onCreateExpense}
            />
          </ListItem>
        )),
    [expenses, onMenuClick, onCreateExpense]
  );

  return (
    <Card sx={expenseDayStyles.root}>
      <CardHeader
        title={cardTitle}
        subheader={today ? dayTitle : undefined}
        action={
          <IconButton onClick={() => onCreateExpense(date)}>
            <AddCircleOutlinedIcon />
          </IconButton>
        }
      ></CardHeader>
      <Divider />
      <CardContent sx={{ pb: 0 }}>
        {today && expenses.length === 0 ? (
          <Typography sx={{ color: '#555', py: 2 }}>No expenses for today.</Typography>
        ) : (
          <>
            <List sx={{ pb: 1 }}>{expensesList}</List>
            <Divider sx={{ mb: '15px' }} />
            <Box>
              {preferences?.expenseDayBudgetsSummary?.length && budgets?.length &&
                preferences.expenseDayBudgetsSummary.map((budgetId) => (
                  <ExpenseDaySummaryItem
                    key={budgetId}
                    title={budgets.find((b) => b.id === budgetId)!.name}
                    value={getBudgetSpent(budgetId, expenses)}
                  />
                ))}
              <ExpenseDaySummaryItem
                title="Day total"
                value={expensesDayTotal}
              />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ExpenseDay;
