import { Alert, Snackbar } from '@mui/material';
import {
  useNotificationContext
} from 'core/providers/NotificationContextProvider';
import { FC } from 'react';

const NotificationPanel: FC = () => {
  const { notification, isNotificationOpen, onCloseNotification } =
    useNotificationContext();
  const defaultProps = {
    open: isNotificationOpen,
    autoHideDuration: 6000,
    onClose: onCloseNotification,
  };
  return notification?.isError ? (
    <Snackbar {...defaultProps}>
      <Alert
        onClose={onCloseNotification}
        severity="error"
        sx={{ width: '100%' }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar {...defaultProps} message={notification?.message} />
  );
};

export default NotificationPanel;
