import { Box, FormControlLabel, List, ListItem, Checkbox } from '@mui/material';
import { useAppSelector } from 'core/hooks/hooks';
import { budgetsSelector } from 'core/store/selectors/budgets.selector';
import { FC } from 'react';

interface IExpenseDayBudgetSummary {
  selectedBudgetIds: string[];
  updateSelectedBudgets: (selectedBudgetIds: string[]) => void;
}

export const ExpenseDayBudgetSummary: FC<IExpenseDayBudgetSummary> = ({
  selectedBudgetIds,
  updateSelectedBudgets,
}) => {
  const { budgets } = useAppSelector(budgetsSelector);

  const toggleSelectedBudget = (budgetId: string) =>
    selectedBudgetIds.includes(budgetId)
      ? updateSelectedBudgets(selectedBudgetIds.filter((b) => b !== budgetId))
      : updateSelectedBudgets([...selectedBudgetIds, budgetId]);

  return (
    <Box>
      <List>
        {budgets.map((b) => (
          <ListItem key={b.id}>
            <FormControlLabel
              label={b.name}
              control={
                <Checkbox
                  sx={{ pt: 0, pb: 0 }}
                  checked={selectedBudgetIds.includes(b.id)}
                  onChange={() => toggleSelectedBudget(b.id)}
                />
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
