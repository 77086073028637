import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { authSelector } from 'core/store/selectors/auth.selector';
import { getTokenInfo } from 'core/services/auth.service';
import { initUser } from 'core/store/reducers/user.reducer';

export const useAuth = () => {
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);
  const { user } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user) {
      const token = localStorage.getItem('token');

      if (!token) return;

      const tokenData = getTokenInfo(token);

      if (!tokenData) return;

      dispatch(
        initUser({
          profileId: tokenData.sub,
          email: tokenData.email,
          emailConfirmed: tokenData.emailConfirmed
        })
      );
    } else {
      setIsUserAuthenticated(true);
    }
  }, []);

  return { isUserAuthenticated };
};
