import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import {
  NotificationContextProvider,
  useNotificationContext
} from 'core/providers/NotificationContextProvider';
import { isExpenseInMonth } from 'core/services/expense.service';
import { getUserData } from 'core/store/actions/userData.actions';
import { groupBy } from 'lodash';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { getMonthItem, isCurrentMonth, isToday } from 'utils/dateUtils';
import { IExpense } from '../../common/entities';
import { useAppDispatch, useAppSelector } from '../../core/hooks/hooks';
import { useContextMenu } from '../../core/hooks/useContextMenu';
import { useEntityContext } from '../../core/hooks/useEntityContext';
import {
  deleteExpense,
  listExpenses
} from '../../core/store/actions/expenses.actions';
import { expensesSelector } from '../../core/store/selectors/expenses.selector';
import NotificationPanel from '../Shared/NotificationPanel';
import EditExpenseForm from './EditExpenseForm/EditExpenseForm';
import ExpenseDay from './ExpenseDay/ExpenseDay';

export interface IExpensePage {
  month: Date;
}

const listItemSx = {
  pt: 0,
  pb: 2,
  pl: 0,
  pr: 0
};

const createExpense = (expense: IExpense): IExpense => ({
  ...expense,
  date: new Date(expense.date),
  createdAt: new Date(expense.createdAt),
  updatedAt: new Date(expense.updatedAt)
});

const groupExpenses = (expenses: IExpense[]) =>
  groupBy(
    expenses.filter(e => e.date),
    e =>
      e.date &&
      new Date(
        e.date.getFullYear(),
        e.date.getMonth(),
        e.date.getDate(),
        0,
        0,
        0,
        0
      )
  );

const ExpensesPage: FC<IExpensePage> = ({ month }) => {
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const { expenses, lastAction, error, hasLoaded, expensesLoaded } =
    useAppSelector(expensesSelector('expenses'));
  const [targetNewExpenseDate, setTargetNewExpenseDate] = useState<
    Date | undefined
  >();
  const { showNotification } = useNotificationContext();
  const context = useEntityContext<string>();
  const contextMenu = useContextMenu<string>(context);
  const dispatch = useAppDispatch();

  const monthExpenses = useMemo(
    () => expenses.filter(e => isExpenseInMonth(e, month)),
    [expenses, month]
  );
  const currentMonth = useMemo(() => isCurrentMonth(month), [month]);

  const currentDate = new Date();
  const hasExpensesToday = monthExpenses.some(e => isToday(new Date(e.date)));

  const groupedExpenses = useMemo(() => {
    const sortedExpenses = monthExpenses
      .map(createExpense)
      .sort((a, b) => (b.date?.getTime() ?? 0) - (a.date?.getTime() ?? 0));
    return groupExpenses(sortedExpenses);
  }, [monthExpenses]);

  useEffect(() => {
    !hasLoaded && dispatch(getUserData());
    !expensesLoaded && dispatch(listExpenses(getMonthItem(month)));
  }, []);

  useEffect(() => {
    if (!isEditFormOpen) {
      contextMenu.closeMenu();
    }
  }, [isEditFormOpen]);

  useEffect(() => {
    switch (lastAction) {
      case deleteExpense.fulfilled.type:
        showNotification({
          message: `Expense has been deleted`
        });
        break;
      case deleteExpense.rejected.type:
        showNotification({ isError: true, message: `Error: ${error}` });
        break;
    }
  }, [lastAction]);

  const handleCloseEditForm = () => {
    setIsEditFormOpen(false);
  };

  const openEditForm = () => {
    contextMenu.hideMenu();
    setIsEditFormOpen(true);
  };

  const handleCreateExpense = (expenseDate: Date) => {
    setTargetNewExpenseDate(expenseDate);
    openEditForm();
  };

  const handleLinkCreateExpense = (e: SyntheticEvent) => {
    e.preventDefault();
    handleCreateExpense(month);
  };

  const renderExpenseDay = useCallback(
    (date: Date, expenses: IExpense[]) => (
      <ExpenseDay
        key={date.toString()}
        openEditForm={setIsEditFormOpen}
        date={date}
        expenses={expenses}
        onMenuClick={contextMenu.menuClick}
        onCreateExpense={handleCreateExpense}
      />
    ),
    [contextMenu]
  );

  const expenseList = useMemo(
    () =>
      Object.entries(groupedExpenses).map(([targetDate, dayExpenses]) => (
        <ListItem key={targetDate} sx={listItemSx}>
          {renderExpenseDay(new Date(targetDate), dayExpenses)}
        </ListItem>
      )),
    [groupedExpenses]
  );

  const renderItemMenu = useCallback(() => {
    const handleDeleteExpense = () => {
      context.current && dispatch(deleteExpense(context.current));
      contextMenu.closeMenu();
    };

    return (
      <Menu
        open={contextMenu.menuOpen}
        anchorEl={contextMenu.anchorEl}
        onClose={contextMenu.closeMenu}
      >
        <MenuItem onClick={openEditForm}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteExpense}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    );
  }, [context]);

  return (
    <Box>
      {!currentMonth && !monthExpenses.length && (
        <Paper sx={{ p: 2 }}>
          <Typography>
            No expenses for this month.{' '}
            <Link href="#" onClick={handleLinkCreateExpense}>
              Add a new one
            </Link>
          </Typography>
        </Paper>
      )}
      <List sx={{ pt: 0 }}>
        {currentMonth && !hasExpensesToday && (
          <ListItem sx={listItemSx}>
            {renderExpenseDay(currentDate, [])}
          </ListItem>
        )}
        {expenseList}
      </List>
      {renderItemMenu()}
      <EditExpenseForm
        isOpen={isEditFormOpen}
        closePanel={handleCloseEditForm}
        targetDate={targetNewExpenseDate}
        expenseId={context.current}
      />
      <NotificationPanel />
    </Box>
  );
};

const ExpensesPageWithContext: FC<IExpensePage> = props => (
  <NotificationContextProvider>
    <ExpensesPage {...props} />
  </NotificationContextProvider>
);

export default ExpensesPageWithContext;
